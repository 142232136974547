import React, { useState, useEffect } from 'react';
import { Alert } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from '../Component/Footer';
import axios from 'axios';
import Logo from '../assets/images/logo.svg'
import { serverApi } from '../config';

const Signup = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [showOtpField, setShowOtpField] = useState(false);
    const [timer, setTimer] = useState(60);
    const [errors, setErrors] = useState({ firstName: '', lastName: '', email: '', otp: '' });
    const [otpExpired, setOtpExpired] = useState(false); // Flag to track OTP expiration
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [resendDisabled, setResendDisabled] = useState(false);

    useEffect(() => {
        let intervalId;

        if (showOtpField && timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [showOtpField, timer]);

    const validateForm = () => {
        let isValid = true;
        const newErrors = { firstName: '', lastName: '', email: '' };

        if (firstName.trim() === '') {
            newErrors.firstName = 'First name is required';
            isValid = false;
        }

        if (lastName.trim() === '') {
            newErrors.lastName = 'Last name is required';
            isValid = false;
        }

        if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const validateFormWithOtp = () => {
        let isValid = true;
        const newErrors = { firstName: '', lastName: '', email: '', otp: '' };

        if (firstName.trim() === '') {
            newErrors.firstName = 'First name is required';
            isValid = false;
        }

        if (lastName.trim() === '') {
            newErrors.lastName = 'Last name is required';
            isValid = false;
        }

        if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid';
            isValid = false;
        }

        if (otp.trim() === '') { // Check if OTP is empty
            newErrors.otp = 'OTP is required';
            isValid = false;
        } else if (otp.trim().length !== 6 || isNaN(otp.trim())) { // Validate OTP format
            newErrors.otp = 'OTP must be a 6-digit number';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSignup = async () => {
        setErrorMessage("")
        setSuccessMessage("")
        if (!validateForm()) {
            return;
        }

        try {
            setResendDisabled(true);
            const response = await axios.post(`${serverApi}/generate-otp-signup`, new URLSearchParams({ email }));

            if (response.data.status === "success") {
                setSuccessMessage(response.data.message);
                setErrorMessage("");
                setShowOtpField(true);
                startTimer();
                setResendDisabled(false);
                setTimeout(() => {
                    setSuccessMessage("");
                }, 15000); // Remove success message after 30 seconds
            } else {
                setErrorMessage(response.data.message);
                setResendDisabled(false);
            }
        } catch (error) {
            setErrorMessage(error.response.data.message);
            setSuccessMessage("");
            setResendDisabled(false);
            console.error('Error creating user:', error.response.data.message);
        }
    };

    const startTimer = () => {
        setTimer(60);
        const intervalId = setInterval(() => {
            setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
        }, 1000);
        setTimeout(() => {
            clearInterval(intervalId);
            setOtpExpired(true); // Mark OTP as expired
        }, 60000); // Stop timer after 60 seconds
    };


    const handleResendOtp = async () => {
        try {
            setErrorMessage("")
            setSuccessMessage("")
            setResendDisabled(true);
            const response = await axios.post(`${serverApi}/generate-otp-signup`, new URLSearchParams({ email }));

            if (response.data.status === "success") {
                setSuccessMessage(response.data.message);
                setResendDisabled(false);
                setErrorMessage("");
                setTimer(60); // Reset timer
                setOtpExpired(false); // Reset OTP expiration flag
                startTimer(); // Start timer again
                setTimeout(() => {
                    setSuccessMessage("");
                }, 15000); // Remove success message after 30 seconds
            } else {
                setErrorMessage(response.data.message);
                setSuccessMessage("");
                setResendDisabled(false);
            }
        } catch (error) {
            setErrorMessage(error.response.data.message);
            setSuccessMessage("");
            setResendDisabled(false);
            console.error('Error resending OTP:', error);
        }
    };

    const handleLogin = async () => {
        try {
            setErrorMessage("")
            setSuccessMessage("")
            if (!validateFormWithOtp()) {
                return;
            }
            // Send request to backend to verify OTP and login
            const response = await axios.post(`${serverApi}/signup`, new URLSearchParams({ firstName, lastName, email, otp }));
            console.log(response.data);
            if (response.data.status === "success") {
                setSuccessMessage(response.data.message);
                localStorage.setItem('userId', response.data.data._id);
                localStorage.setItem('firstName', response.data.data.firstName);
                localStorage.setItem('lastName', response.data?.data?.lastName === undefined ? '' : response.data?.data?.lastName);
                localStorage.setItem('email', response.data.data.local.email);
                localStorage.setItem('permission', response.data.data.userType);
                localStorage.setItem('image', response.data.data.image);
                navigate('/')
            } else {
                setErrorMessage(response.data.message);
                setSuccessMessage("")
            }
        } catch (error) {
            setErrorMessage(error.response.data.message);
            setSuccessMessage("");
        }
    };

    return (
        <div>
            <Header />
            <div className="container">
                <div className="row justify-content-center align-items-center min-vh-100">
                    <div className="col-md-6">
                        <div className="card login-form-section sign-up-mage">
                            <div className='login-logo text-center'>
                                <img src={Logo} />
                            </div>
                            <div className="card-body">
                                <h1 className="card-title text-center">Signup</h1>
                                {successMessage && (
                                    <Alert variant="success" onClose={() => setSuccessMessage("")} dismissible>
                                        {successMessage}
                                    </Alert>
                                )}
                                {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                        placeholder="First name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                    {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                        placeholder="Last name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                    {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="email"
                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                </div>
                                {!showOtpField && (
                                    <div className="mb-3">
                                        <button className="btn cmn-btn w-100" onClick={handleSignup} disabled={resendDisabled}>Generate Otp</button>
                                    </div>
                                )}
                                {showOtpField && (
                                    <>
                                        <div className="mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.otp ? 'is-invalid' : ''}`}
                                                placeholder={`Enter OTP (${timer})`}
                                                value={otp}
                                                onChange={(e) => setOtp(e.target.value)}
                                            />
                                            {errors.otp && <div className="invalid-feedback">{errors.otp}</div>}
                                        </div>
                                        {otpExpired ? (
                                            <div className="mb-3">
                                                <button className="btn btn-danger w-100" onClick={handleResendOtp} disabled={resendDisabled}>Resend OTP</button>
                                            </div>
                                        ) : (
                                            <div className="mb-3">
                                                <button className="btn cmn-btn w-100" onClick={handleLogin}>Signup</button>
                                            </div>
                                        )}
                                    </>
                                )}
                                <div className='row'>
                                    <div class="col-md-12">
                                        <p style={{ "textAlign": "center" }}>If you already have an existing account, please <Link style={{"color": "darkblue"}} to="/login">click here</Link> to login</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Signup;
