import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { serverApi } from '../config';
import Avatar from "../assets/images/profile-auto.png"
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import { Alert } from 'react-bootstrap';

const Profile = () => {
    const fileInputRef = useRef(null);
    const [image, setImage] = useState();
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        file: null
    });
    const [errors, setErrors] = useState({
        firstName: '',
        email: ''
    });

    useEffect(() => {
        getProfile();
    }, []);

    async function getProfile() {
        const userId = window.localStorage.getItem('userId');
        const response = await axios.get(`${serverApi}/get_profile/${userId}`);
        if (response.data.status === 'success') {
            setFormData({
                firstName: response.data.data.firstName,
                lastName: response.data.data.lastName,
                email: response.data.data.local.email,
                // Update other fields as needed
            });
            // console.log(serverApi + "/" + response.data.image);
            if (response.data.data.image) {
                setImage(serverApi + "/" + response.data.data.image);
            } else {
                setImage(Avatar);
            }

        } else {
            alert(response.message);
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleEditClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileType = file.type.split('/')[0]; // Get the file type (image, video, etc.)
            if (fileType === 'image') {
                setFormData({ ...formData, file: file });
            } else {
                setErrorMessage('Only image files are allowed.'); // Show an alert or handle the error appropriately
            }
        }
        setTimeout(() => {
            setSuccessMessage("");
            setErrorMessage("");
        }, 5000); // Remove success message after 30 seconds
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};
        if (!formData.firstName) {
            newErrors.firstName = 'First name is required';
        }
        if (!formData.email) {
            newErrors.email = 'Email is required';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            const formDataToSend = new FormData();
            formDataToSend.append('firstName', formData.firstName);
            formDataToSend.append('lastName', formData.lastName);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('file', formData.file);
            formDataToSend.append('userId', window.localStorage.getItem('userId'));

            try {
                const response = await axios.post(`${serverApi}/update_user_profile`, formDataToSend);
                if (response.status === 200 && response.data.status === 'success') {
                    setSuccessMessage("Profile updated successfully");
                    setErrorMessage("");
                    localStorage.setItem('firstName', response.data.data.firstName);
                    localStorage.setItem('lastName', response.data?.data?.lastName === '' ? '' : response.data?.data?.lastName);
                    localStorage.setItem('email', response.data.data.local.email);
                    localStorage.setItem('image', response.data.data?.image);
                } else {
                    setSuccessMessage("");
                    setErrorMessage(response.data.message);
                }
            } catch (error) {
                setSuccessMessage("");
                setErrorMessage(error.response.data.message);
                console.error('Error updating profile:', error);
            }
        }
        setTimeout(() => {
            setSuccessMessage("");
            setErrorMessage("");
        }, 5000); // Remove success message after 30 seconds
    };

    return (
        <div>
            <Header />
            <div className="container py-5 mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card login-form-section">
                            {successMessage && (
                                <Alert variant="success" onClose={() => setSuccessMessage("")} dismissible>
                                    {successMessage}
                                </Alert>
                            )}
                            {errorMessage && (
                                <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                    {errorMessage}
                                </Alert>
                            )}
                            {/* <div className="card-header">
                                <h5 className="card-title mb-0">Profile</h5>
                            </div> */}
                            <div className="card-body">
                                <div className="text-center mb-3">
                                    <img
                                        alt="Profile"
                                        style={{ height: "100px", width: "100px" }}
                                        src={formData.file ? URL.createObjectURL(formData.file) : image}
                                        className="img-fluid rounded-circle"
                                    />
                                    <div>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            onChange={handleFileChange}
                                        />
                                        <button className="btn btn-sm  cmn-btn mt-2 upload-img-btn" onClick={handleEditClick}>
                                            Edit Photo
                                        </button>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">First Name</label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                            placeholder="Enter first name"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleInputChange}
                                        />
                                        {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Last Name</label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                            placeholder="Enter last name"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleInputChange}
                                        />
                                        {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                            placeholder="Enter your email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />
                                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                    </div>
                                    <button type="submit" className="btn cmn-btn w-100 mt-4">Update Profile</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Profile;
