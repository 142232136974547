import React from "react";
import Header from "../Component/Header";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import Background from "../assets/images/background-patterns.svg";
import Mail from "../assets/images/mail-icon.svg";
import Call from "../assets/images/ca1l-icon.svg";
import Facebook from "../assets/images/facebook-1.svg";
import Tiktok from "../assets/images/tiktok-1.svg";
import Insta from "../assets/images/insta-1.svg";
import Youtube from "../assets/images/youtube-1.svg";

import Footer from "../Component/Footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <img className="vatro-img" src={Background} />
      <section className=" hero-section-2">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} md={12}>
              <div className="section-heading text-center">
                <h2>Privacy Policy</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="policy-term">
        <Container>
          <div className="outr-box">
            <h2>
              Privacy Policy for ShareHeart{" "}
              <span>(Effective Date: April 2024)</span>
            </h2>
            <p>
              Welcome to ShareHeart, a digital platform dedicated to sharing and
              spreading the power of Christian faith through personal
              testimonies. This Privacy Policy outlines how we collect, use,
              protect, and share information about our users ("you") when you
              use our website, mobile application ("app"), and services
              (collectively, "Services").
            </p>
            <h2>Information We Collect</h2>
            <p>
              Personal Information: When you register with ShareHeart, we may
              collect personal information such as your name, email address, and
              location. If you choose to record your testimony through our
              Services, we will also collect the video and any personal data you
              choose to include in it.
            </p>
            <p>
              Technical Data: We automatically collect certain technical
              information from your device, including your IP address, browser
              type, device type, and usage data, such as how you interact with
              our Services.
            </p>
            <h2>How We Use Your Information</h2>
            <h3>We use your information to:</h3>
            <ul>
              <li>Provide, maintain, and improve our Services;</li>
              <li>  Record, host, and stream your testimony videos;</li>
              <li>  Communicate with you about your account or our Services;</li>
              <li>  Enhance user experience and develop new features;</li>
              <li>  Comply with legal obligations and enforce our terms and policies.</li>
            </ul>
            <h2>Sharing Your Information</h2>
            <h3>We do not sell your personal information. However, we may share your information with:</h3>
            <ul>
              <li>Service providers who assist us in operating our Services, such as hosting and streaming services, subject to confidentiality agreements;</li>
              <li>  Law enforcement or other government agencies if required by law or to protect our rights and the safety of our users;</li>
              <li>  Third parties in connection with a merger, acquisition, or sale of our assets.</li>
            </ul>
            <h2>Your Choices and Rights</h2>
            <p>You have the right to access, correct, or delete your personal information stored with us. You can also object to or restrict certain processing of your data. To exercise these rights, please contact us at [contact information].</p>
            <h2>Data Security</h2>
            <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no internet-based service can be 100% secure, so we cannot guarantee absolute security.</p>
            <h2>Children's Privacy</h2>
            <p>Our Services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information from our files as soon as possible.</p>
            <h2>Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective date" at the top. We encourage you to review this Privacy Policy periodically for any changes.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy or our privacy practices, please contact us at: Hello@shareheart.com</p>
          </div>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
