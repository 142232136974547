import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const VideoPlayer = ({ videoUrl, loaderFun, isMuted, activeIndex }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted;
    }
  }, [isMuted]);

  useEffect(() => {
    if (activeIndex) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [activeIndex]);

  const clickVideo = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }

  


  return (
    <>
      <video
        ref={videoRef}
        playsInline
        muted={isMuted}
        preload="auto"
        autoPlay
        loop
        onLoadedData={loaderFun}
        // controls
        className="h-100"
        data-setup='{"autoplay":"any"}'
        onClick={clickVideo}
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
    </>
  );
};

VideoPlayer.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  isMuted: PropTypes.bool.isRequired,
  onToggleMute: PropTypes.func.isRequired,
};

export default VideoPlayer;
