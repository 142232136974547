import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Play from "../assets/images/play-icon.svg";
import Sliderimg from "../assets/images/slider-img.png";
import Shap1 from "../assets/images/shap-1.svg";
import Round from "../assets/images/round.svg";
import Fill1 from "../assets/images/fill-1.svg";

import Videocall from "../assets/images/video-call.webp";
import Coordinators from "../assets/images/co-ordinators.png";
import T1 from "../assets/images/t-1.webp";
import T2 from "../assets/images/t-2.webp";
import T3 from "../assets/images/t-3.webp";
import T4 from "../assets/images/t-4.webp";
import T5 from "../assets/images/t-5.webp";
import T6 from "../assets/images/t-6.webp";
import T7 from "../assets/images/t-7.webp";
import T8 from "../assets/images/t-8.webp";
// import T9 from "../assets/images/t-9.jpg";
import T10 from "../assets/images/t-10.jpg";
import Googleplay from "../assets/images/google-play.svg";
import Appstore from "../assets/images/app-store.svg";
import Appscreen from "../assets/images/app-screen.png";
// import Video1 from "../assets/images/video-1.mov";
import Video1 from "../assets/images/video-1.gif";
// import Video2 from "../assets/images/video-2.mov";
import Video2 from "../assets/images/video-2.gif";
// import Video3 from "../assets/images/video-3.mov";
import Video3 from "../assets/images/video-3.gif";
// import Videoman from "../assets/images/video-man.mp4";
import Videoman from "../assets/images/video-man.gif";
import Checkicon from "../assets/images/check-icon.svg";
import Footer from "../Component/Footer";
import axios from "axios";
import { serverApi } from "../config";

const Home = () => {

  useEffect(() => {
    AOS.init(); // Initialize AOS library
  }, []);

  const navigate = useNavigate();
  const colors = ["c-bg-1", "c-bg-2", "c-bg-3", "c-bg-4"];
  const [HashtagList, setHastagList] = useState([]);
  const [faqList, setFaqList] = useState([]);

  useEffect(() => {
    getHashtag();
    getFaq();
  }, []);

  async function getHashtag() {
    try {
      const response = await axios.get(
        `${serverApi}/web/get_hashtag?type=home`
      );
      if (response.data.status === "success") {
        setHastagList(response.data.data);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function getFaq() {
    try {
      const response = await axios.get(`${serverApi}/web/get_faq`);
      if (response.data.status === "success") {
        setFaqList(response.data.data);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  var settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function watchNow() {
    navigate("/hashtags");
  }

  function getInTouch() {
    navigate("/share");
  }

  function uploadVideo() {
    navigate("/upload");
  }

  return (
    <div>
      <Header />
      <section className="hero-section">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="">
                <h1 >
                  Real People <br /> Real Stories
                </h1>
                <p >Lives Impacted by God</p>
                <button onClick={watchNow} className="cmn-btn rounded-pill slider-btn" >
                  Watch Now <img className="img-fluid ms-2" src={Play} />
                </button>
              </div>
            </Col>
            <Col md={6}>


              <div className="side-img hero-imgs">
                <img className="img-fluid shap-1"  src={Shap1} />
                <img className="img-fluid shap-2"  src={Round} />
                <img className="img-fluid shap-3"  src={Fill1} />

                <Row className="video-sldr">
                  <Col className="text-end">
                    {/* <video class="video-man-1"   loop autoPlay muted  >
                <source src={Video1} type="video/mp4" />
                </video>   */}
                    <img className=" video-man-1 img-fluid "  src={Video1} />
                  </Col>
                  <Col className=" text-center">
                    {/* <video class="video-man-2"    loop autoPlay muted  >
                  <source src={Video2} type="video/mp4" />
                  </video>  */}
                    <img className=" video-man-2 img-fluid "  src={Video2} />
                    <img className=" video-man-3 img-fluid "  src={Video3} />
                    {/* <video class="video-man-3"    loop autoPlay muted  >
                  <source src={Video3} type="video/mp4" />
                  </video>  */}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* {HashtagList.length >= 4 ? */}
        <section className="stories-section">
          <Container>
            <div className="section-heading">
              <h2 className="mb-4" >
                Watch encouraging real life Stories <br />
                for every challenging situations
              </h2>
              <span >FEATURED PLAYLISTS</span>
            </div>
            <div className="slider-container">
              <Slider {...settings}>
                {HashtagList.map((item, i) => {
                  return (
                    <Link to={"/" + item.slug} key={i}>
                      <div className="slide-s" >
                        <div className={`me-4 stories-category ${colors[i % 4]}`}>
                          <h3 >{item.name}</h3>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </Slider>
          </div>
        </Container>
      </section>
      {/* : ''} */}

      <section className="video-section">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section-heading text-center">
                <h2 >Need guidance on sharing your story?</h2>
                <p >
                  Get in touch with us for a free video recording call. Our
                  coordinators will talk <br /> with you on the call to
                  discover, refine, and record your story.
                </p>
                <div className="record-video-img">
                  <img  className="img-fluid mt-4" src={Videocall} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="Our-co-ordinators">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>

              <div className="test-animtion">
                <div className="inr-img" >
                  <marquee className="h-100" direction="up" scrollamount="11" loop behavior="alternate">
                  <img className="img-fluid" src={T1} />
                    <img className="img-fluid" src={T2} />
                    <img className="img-fluid" src={T3} />
                    <img className="img-fluid" src={T7} />
                    <img className="img-fluid" src={T8} />
                    <img className="img-fluid" src={T10} />
                     
                      </marquee>
                </div>
                <div className="inr-img" >
                  <marquee className="h-100" direction="down" scrollamount="11" loop behavior="alternate">
                    <img className="img-fluid" src={T4} />
                    <img className="img-fluid" src={T5} />
                    <img className="img-fluid" src={T7} />
                    <img className="img-fluid" src={T8} />
                    <img className="img-fluid" src={T10} />
                    <img className="img-fluid" src={T6} />
                  </marquee>
                </div>
                <div  className="inr-img">
                  <marquee className="h-100" direction="up" scrollamount="11" loop behavior="alternate">

                    <img className="img-fluid" src={T7} />
                    <img className="img-fluid" src={T8} />
                    <img className="img-fluid" src={T10} />
                    <img className="img-fluid" src={T1} />
                    <img className="img-fluid" src={T2} />
                    <img className="img-fluid" src={T3} />
                  </marquee>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="">
                <h2 >
                  Our testimony
                  <br /> co-ordinators
                </h2>
                <p >
                  Our compassionate and empathetic testimony co-ordinators, will
                  reach-out to you to and schedule a video call
                </p>
                <button onClick={getInTouch} className="cmn-btn px-5 mt-4" >
                  Get in Touch
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="mobile-app-section">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="section-heading">
                <h2 >Install our App</h2>
                <p >
                  Discover a community, Watch uplifting stories, Get Inspired,
                  Share your story
                </p>
              </div>
              <div className="app-link">
                <Link to="https://play.google.com/store/apps/details?id=com.shareheartapp.app" >
                  <img className="img-fluid" src={Googleplay} />
                </Link>
                <Link to="https://apps.apple.com/us/app/shareheart/id6449276730" >
                  <img className="img-fluid" src={Appstore} />
                </Link>
              </div>
            </Col>
            <Col md={6} className="text-end">
              {/* <img className="img-fluid" src={Appscreen} /> */}
              <div className="video-session" >
                {/* <video className="img-fluid"  loop autoPlay muted  >
      <source src={Videoman} type="video/mp4"/>
     </video> */}
                <img className="img-fluid" src={Videoman} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="faq-section">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} md={12}>
              <div className="section-heading">
                <h2 >Your story matters</h2>
                <p >
                  Every believer has a story, and every story has a purpose.
                  <br />
                  Every believer’s testimony can be uncovered by asking these
                  two simple questions
                </p>
              </div>
              <div className="services-box" >
                <img className="img-fluid" src={Checkicon} />
                <div className="w-100">
                  {/* <h3>Noise Free Locations</h3> */}
                  <p>
                    Can you share an incident or experience in your life where{" "}
                    <br /> Jesus proved Himself to be real to you?
                  </p>
                </div>
              </div>
              <div className="services-box" >
                <img className="img-fluid" src={Checkicon} />
                <div className="w-100">
                  {/* <h3>24/7 Hour Support</h3> */}
                  <p>
                    What would you like to tell others going through a similar{" "}
                    <br />
                    situation, and how could Jesus help them?
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="faq-accordn" >
                <Accordion defaultActiveKey={0}>
                  {faqList.map((item, i) => {
                    return (
                      <Accordion.Item eventKey={i}>
                        <Accordion.Header>{item.question}</Accordion.Header>
                        <Accordion.Body>
                          <p>{item.answer}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                  {/* <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      What story should I share?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        {" "}
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How do you record my story?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item> */}
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="get-touch-section">
        <div className="section-heading mb-0">
          <h2 >Ready to share ? Upload now</h2>
          <p >If you already have a recorded testimony video, Please upload it here</p>
          <button className="btsrp-btn"  onClick={uploadVideo}>Upload video now</button>
        </div>
      </section>

      <section>
        <div className="section-heading text-center my-5 thanks-section">
          <p>
            As a thank you, we send you a free digital book <br />
            “Timeless Testimonies Retold”
          </p>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
