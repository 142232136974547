import React, { useRef, useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import loader from '../assets/images/ball-triangle.svg';
import Background from "../assets/images/background-patterns.svg";
import Like from "../assets/images/like.svg";
import redLike from "../assets/images/redLike.svg";
import Volume from "../assets/images/volume.svg";
import Nosound from "../assets/images/no-sound.svg";
import Cancel from "../assets/images/cancel.svg";
import Share from "../assets/images/share.svg";
import { serverApi } from "../config";
import axios from "axios";
import { debounce } from 'lodash'; // Import debounce from lodash library
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Youtube from "../assets/images/youtube.svg";
import Facebbok from "../assets/images/logo-facebook.svg";
import Instagram from "../assets/images/logo-instagram.svg";
import Tiktok from "../assets/images/tiktok.svg";
import VideoPlayer from "../Component/VideoPlayer";
import Swal from "sweetalert2";
import ShareVideo from "../Component/ShareVideo";
import bowser from 'bowser';
// import { isMobile as isMobileNew } from 'react-device-detect';

const Video = () => {
  const location = useLocation();
  const sliderRef = useRef(null);
  const params = useParams();
  const videoRef = useRef(null);
  const { slug } = useParams();
  const navigate = useNavigate();
  const [videoList, setVideoList] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [mutedVideos, setMutedVideos] = useState({});
  const [activeSlide, setActiveSlide] = useState(0);
  const [displayTexts, setDisplayTexts] = useState({});
  const [isMute, setIsMute] = useState(true);
  const [videoUrl, setShareUrl] = useState();
  const [loading, setLoading] = useState(true);
  const [copyTxt, setCopyTxt] = useState("Copy")
  const [isDevice, setIsDevice] = useState(false);
  // useEffect(() => {
  //   // Example: Move slider to index 2 when a certain condition is met
  //   // if (someConditionIsMet) {
  //   if (sliderRef.current) {
  //     sliderRef.current.slickGoTo(location.state ? location.state.index : 0);
  //   }
  //   // }
  // }, [sliderRef.current]); // Include any dependencies that should trigger this effect

  // useEffect(() => {
  //   const autoplayEnabled = !isMobileNew; // Check if the device is not mobile for autoplay

  //   if (sliderRef.current) {
  //     sliderRef.current.slickGoTo(activeSlide); // Move slider to index
  //     if (autoplayEnabled) {
  //       sliderRef.current.innerSlider.list.querySelectorAll('video').forEach((video) => {
  //         video.play(); // Autoplay videos if not on a mobile device
  //       });
  //     }
  //   }
  // }, [sliderRef.current, location.state]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);
    window.scrollTo(0, 0);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  useEffect(() => {
    const browserData = bowser.getParser(window.navigator.userAgent);
    if (browserData.parsedResult?.browser?.name === 'Chrome' && browserData.parsedResult?.os?.name === 'iOS') {
      setIsDevice(true);
    } else {
      setIsDevice(false);
    }
  }, [isDevice]);

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
  }, [isMobile]);

  useEffect(() => {
    getHashtagVideo(true);
  }, [])

  useEffect(() => {
    const handleWheel = debounce((e) => {
      e.preventDefault();
      if (sliderRef.current) {
        if (e.deltaY > 0) {
          sliderRef.current.slickNext();
        } else {
          sliderRef.current.slickPrev();
        }
      }
    }, 35); // Debounce time in milliseconds

    const sliderElement = sliderRef.current?.innerSlider.list;
    if (sliderElement) {
      sliderElement.addEventListener('wheel', handleWheel, { passive: false });
      return () => {
        sliderElement.removeEventListener('wheel', handleWheel);
      };
    }
  }, []);

  useEffect(() => {
    let touchStartY = null;

    const handleTouchStart = (e) => {
      touchStartY = e.touches[0].clientY;
    };

    const handleTouchEnd = (e) => {
      if (!touchStartY) {
        return;
      }

      const touchEndY = e.changedTouches[0].clientY;
      const deltaY = touchEndY - touchStartY;
      if (Math.abs(deltaY) > 50) {
        if (deltaY < 0) {
          sliderRef.current.slickNext(); // Swipe up triggers next slide
        } else {
          sliderRef.current.slickPrev(); // Swipe down triggers previous slide
        }
      }

      touchStartY = null;
    };

    const sliderElement = sliderRef.current?.innerSlider.list;
    if (sliderElement) {
      sliderElement.addEventListener('touchstart', handleTouchStart);
      sliderElement.addEventListener('touchend', handleTouchEnd);

      return () => {
        sliderElement.removeEventListener('touchstart', handleTouchStart);
        sliderElement.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, []);
  // This code assumes you have initialized `touchStartY` somewhere in your component.
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    afterChange: (current) => {
      // alert(current)
      setActiveSlide(current);

    },
  };

  const toggleMute = (videoId) => {
    if (isDevice) {
      setMutedVideos((prevState) => ({
        ...prevState,
        [videoId]: !prevState[videoId], // Toggle mute state for the specific video
      }));
    } else {
      const updatedMutedVideos = {};
      videoList.forEach((item) => {
        updatedMutedVideos[item._id] = videoId;
      });
      setMutedVideos(updatedMutedVideos);
      setIsMute(videoId);
    }
  };

  // const toggleMute = (videoId) => {
  //   setMutedVideos((prevState) => ({
  //     ...prevState,
  //     [videoId]: !prevState[videoId], // Toggle mute state for the specific video
  //   }));
  // };

  async function getHashtagVideo(isMute) {
    try {
      const userId = window.localStorage.getItem('userId');
      var user;
      if (userId !== null) {
        user = window.localStorage.getItem('userId');
      } else {
        user = "";
      }
      const response = await axios.get(`${serverApi}/web/get_hashtag_video/${slug}?userId=${user}`);
      if (response.data.status === "success") {
        // Assuming response.data.data?.videoList is your array of video objects

        if (params.videoId) {
          const videoList = response.data.data?.videoList || [];

          // Find the index of the matched video
          const matchedIndex = videoList.findIndex(video => video._id === params.videoId);

          if (matchedIndex !== -1) {
            // Calculate indices after and before the matched index
            const afterIndices = videoList.slice(matchedIndex + 1).map((_, idx) => idx + matchedIndex + 1);
            const beforeIndices = videoList.slice(0, matchedIndex).map((_, idx) => idx);

            // Set the indices array with matched index first, indices after, and indices before
            const indicesArray = [matchedIndex, ...afterIndices, ...beforeIndices];

            // Rearrange the video list based on indicesArray
            var rearrangedVideos = indicesArray.map(index => videoList[index]);

            console.log("rearrangedVideos", rearrangedVideos);
          } else {
            console.log('Matched video not found');
          }
          // Filter the video list
          const matchedVideos = videoList.filter(video => video._id === params.videoId);
          const unmatchedVideos = videoList.filter(video => video._id !== params.videoId);
          // Create a new array with the first matched video at the first index
          const newList = matchedVideos.length > 0 ? [matchedVideos[0], ...unmatchedVideos] : unmatchedVideos;

          // Set the new list as the video list
          setVideoList(rearrangedVideos);
        } else {

          setVideoList(response.data.data?.videoList);
        }
        if (isMute) {
          if (response.data.data?.videoList?.length > 0) {
            const data = response.data.data?.videoList;
            const mutedVideoIds = data.reduce((acc, item) => {
              acc[item._id] = true;
              return acc;
            }, {});
            setMutedVideos(mutedVideoIds);
          }
        }
        // Initialize displayTexts state with default values for each video
        const initialDisplayTexts = response.data.data?.videoList.reduce((acc, item) => {
          acc[item._id] = false; // Initially set all descriptions as collapsed
          return acc;
        }, {});
        setDisplayTexts(initialDisplayTexts);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function likeVideo(videoId) {
    try {
      const userId = window.localStorage.getItem('userId');
      if (userId !== null) {
        var data = {
          videoId: videoId,
          userId: userId
        }
        const response = await axios.post(`${serverApi}/web/like`, new URLSearchParams(data));
        if (response.data.status === 'success') {
          getHashtagVideo(false);
          // toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } else {
        // async function logout() {
        Swal.fire({
          title: 'Like this video?',
          text: "Log in now to like video.",
          icon: 'false',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate('/login')
          }
        })

        // }

      }
    } catch (error) {
      alert(error.response.data.message);
      console.error('Error resending OTP:', error);
    }
  }
  const handleVideoLoaded = () => {
    setLoading(false); // Set loading to false once video is loaded
  };

  const [isActive, setIsActive] = useState(false);
  const [displayText, setDisplayText] = useState("See More");

  const toggleDisplayText = (videoId) => {
    setDisplayTexts(prevState => ({
      ...prevState,
      [videoId]: !prevState[videoId], // Toggle displayText state for the specific video
    }));
  };


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  async function handleShow(videoUrl) {
    setShareUrl(videoUrl)
    setShow(true);
  }

  const handleCopyClick = () => {
    navigator.clipboard.writeText(videoUrl)
      .then(() => {
        setCopyTxt("Copied");
        setTimeout(() => setCopyTxt("Copy"), 2000);
      })
      .catch(err => console.error('Failed to copy:', err));
  };

  async function redirectVideoPage(index) {
    const newState = {
      index: index
    };
    navigate(`/${slug}#${index}`, { state: newState })
  }


  return (
    <div>
      <ToastContainer closeButton={true} position="top-right" />
      <div className='container-new'>
        {/* <h4>With Mouse Scroll</h4> */}
        <div>
          <div className="vatro-img vh-100">
            <img className="img-fluid" src={Background} />
          </div>
          <section className="hero-section-2 py-0 video-mange-pr">
            {loading && <div className='loder-mange'><img src={loader} /></div>}
            <Container>
              <div className="slider-container">

                <Slider ref={sliderRef} {...settings}>
                  {videoList.map((item, i) => (
                    <div className="vd-outer" key={i}> {/* Add key to each div */}
                      <div className="reels">
                        {/* <Link className="back-btn" to={`/${slug}`}>
                          <img className="img-fluid" src={Cancel} />
                        </Link> */}
                        <div style={{ "cursor": "pointer" }} className="back-btn" onClick={() => redirectVideoPage(item._id)}>
                          <img className="img-fluid" src={Cancel} />
                        </div>
                        <VideoPlayer videoUrl={item.videoLink} loaderFun={handleVideoLoaded} activeIndex={activeSlide == i ? true : false} isMuted={activeSlide !== i || mutedVideos[item._id] ? true : false} />
                        {/* <video
                          key={i}
                          ref={videoRef}
                          playsInline
                          muted={activeSlide == i && !isMute ? false : true} // Ensure muted attribute is set only when needed
                          preload="auto"
                          autoplay="autoplay"
                          loop
                          // controls
                          className="h-100"
                          data-setup='{"autoplay":"any"}'
                          onClick={() => clickVideo()}
                        >
                          <source src={serverApi + item.videoLink} type="video/mp4" />
                        </video> */}
                        {isDevice ?
                          <button className={`sound-none-shw ${mutedVideos[item._id] ? 'muted' : 'unmuted'}`} onClick={() => toggleMute(item._id)}> {mutedVideos[item._id] ? <img src={Nosound} /> : <img src={Volume} />} </button> :
                          <button className={`sound-none-shw ${mutedVideos[item._id] ? 'muted' : 'unmuted'}`} onClick={() => toggleMute(!isMute)}> {mutedVideos[item._id] ? <img src={Nosound} /> : <img src={Volume} />} </button>}
                        {/* <button className={`sound-none-shw ${mutedVideos[item._id] ? 'muted' : 'unmuted'}`} onClick={() => toggleMute(item._id)}> {mutedVideos[item._id] ? <img src={Nosound} /> : <img src={Volume} />} </button> */}
                        {/* <button className={`sound-none-shw ${mutedVideos[item._id] ? 'muted' : 'unmuted'}`} onClick={() => toggleMute(!isMute)}> {mutedVideos[item._id] ? <img src={Nosound} /> : <img src={Volume} />} </button> */}
                        <div className="dtl-vd">
                          <div className="action-img">

                            <img className="img-fluid" onClick={() => likeVideo(item._id)} src={item.likes ? redLike : Like} />
                            <h5 className="mt-3 me-2" style={{ color: "white" }}>{item.totalLikes}</h5>

                          </div>
                          <div className="action-img">

                          </div>
                          <div className="action-img ">
                            <img className="img-fluid" onClick={() => handleShow(item.videoLink)} src={Share} />
                          </div>
                          <h3>{item.name}</h3>
                          <p className={displayTexts[item._id] ? 'active' : ''}>
                            {item.description}
                          </p>
                          {item.description?.length > 100 ?
                            <button className="see-more-btn" onClick={() => toggleDisplayText(item._id)}>
                              {displayTexts[item._id] ? "See Less" : "See More"}
                            </button>
                            : ''}
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </Container>
          </section>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="share-mdl">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="share-modal text-center">
            <h2>Share this video</h2>
            <p>Share this link via</p>
            <ShareVideo videoUrl={videoUrl} />

            <p className="text-start mb-0">Or copy link</p>
            <div class="field">
              <i class="url-icon uil uil-link"></i>
              <input type="text" readonly value={videoUrl} />
              <button onClick={handleCopyClick}>{copyTxt}</button>
            </div>
            <button onClick={handleClose} className="cmn-btn btn d-inline-block">Done</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Video;
