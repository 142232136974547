import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Background from "../assets/images/background-patterns.svg";
import Playbutton from "../assets/images/play-button.svg";
import Logo from "../assets/images/logo-2.svg";
import axios from "axios";
import { serverApi } from "../config";

const Relationship = () => {
  const scrollRef = useRef(null); // Ref for the scroll container
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();
  const [name, setName] = useState('');
  const [link, setLink] = useState('');
  const [description, setDescription] = useState('');
  const [videoList, setVideoList] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    document.body.classList.remove('overflow-hidden');
    getHashtagVideo();
  }, [])

  useEffect(() => {
    // Scroll to the element with the ID "scrollToId3" when the component mounts
    const scrollToElement = document.getElementById(`scrollToId${location.state ? location.state.index : 0}`);
    setTimeout(() => {
      if (scrollToElement) {
        scrollToElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }, 1000);
  }, [videoList, scrollRef.current]);


  async function getHashtagVideo() {
    try {
      const response = await axios.get(`${serverApi}/web/get_hashtag_video/${slug}`);
      if (response.data.status === "success") {
        setName(response.data.data?.name);
        setLink(response.data.data?.link);
        setDescription(response.data.data?.description);
        setVideoList(response.data.data?.videoList);
        setIsLoad(true);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function redirectVideoPage(index, id) {
    const newState = {
      index: index,
      id: id
    };
    navigate(`/${slug}/video`, { state: newState })
  }

  return (
    <div>
      <div className="vatro-img vh-100">
        <img className="img-fluid" src={Background} />
      </div>
      <Container>
        {isLoad ?
          <div className="logo-hdr-2 my-5">
            <Link to={link}><img src={Logo} /></Link>
            <div className="section-heading w-100 text-center mb-0">
              <h2>#{name}</h2>
              <p>{description}</p>
            </div>
          </div> : ''}
      </Container>
      <section className="type-assdf">
        <Container>
          <div className="video-view">
            {videoList.map((item, i) => {
              return (
                <div className="video-img" id={`scrollToId${item._id}`} ref={scrollRef}>
                  <img src={item.thumbnail.includes("/resources/") ? serverApi + item.thumbnail : item.thumbnail} />
                  <Link to={`/${slug}/video/${item._id}`}>
                    <span>
                      <img className="img-fluid" src={Playbutton} />
                    </span>
                  </Link>
                </div>
              )
            })
            }
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Relationship;
