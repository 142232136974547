import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Hashtags from "./Pages/Hashtags";
import Relationship from "./Pages/Relationship";
import Video from "./Pages/Video";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import HelpDesk from "./Pages/HelpDesk";
import Share from "./Pages/Share";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsService from "./Pages/TermsService";
import Volunteer from "./Pages/Volunteer";
import InstallApp from "./Pages/InstallApp";
 

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import UploadVideo from "./Pages/UploadVideo";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import Profile from "./Pages/Profile";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/hashtags" element={<Hashtags />} />
        <Route path="/:slug" element={<Relationship />} />
        <Route path="/:slug/video" element={<Video />} />
        <Route path="/:slug/video/:videoId" element={<Video />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/help-desk" element={<HelpDesk />} />
        <Route path="/share" element={<Share />} />
        <Route path="/upload" element={<UploadVideo />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-service" element={<TermsService />} />
        <Route path="/volunteer" element={<Volunteer />} />
        <Route path="/install" element={<InstallApp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
