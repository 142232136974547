import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import { Accordion, Button, Col, Container, Row, Spinner } from "react-bootstrap";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";

import Background from "../assets/images/background-patterns.svg";
import Videoimg from "../assets/images/video-bg.png";
import Playbutton from "../assets/images/play-button.svg";

import Footer from "../Component/Footer";
import Masonry from "react-responsive-masonry";
import axios from "axios";
import { serverApi } from "../config";

const Hashtags = () => {
  const navigate = useNavigate();
  const colors = ['c-bg-1', 'c-bg-2', 'c-bg-3', 'c-bg-4'];
  const [HashtagList, setHastagList] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    getHashtag();
  }, [])

  async function getHashtag() {
    try {
      const response = await axios.get(`${serverApi}/web/get_hashtag`);
      if (response.data.status === "success") {
        console.log(response.data.data);
        setHastagList(response.data.data);
        setLoading(false); 
      } else {
        console.log(response.data.message);
        setLoading(false); 
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false); 
    }
  }

  return (
    <div>
      <Header />
      <img className="vatro-img" src={Background} />
      <section className="stories-section hero-section-2">
        <Container>
          <div className="section-heading text-center mb-5">
            <h2>Hashtags</h2>
          </div>
          <Row>
            {/* Centered column for loader */}
            {loading ? ( // Conditional rendering based on loading state
              <Col className="text-center">
                <Spinner animation="border" role="status">
                  <span className="sr-only"></span>
                </Spinner>
              </Col>
            ) : (
              HashtagList.map((item, i) => (
                <Col xl={3} md={4} key={item.slug}> {/* Added key prop to each child */}
                  <Link to={"/" + item.slug}>
                    <div className={`stories-category-blog ${colors[i % 4]}`}>
                      <h3>{item.name}</h3>
                      <p>{item.description}</p>
                    </div>
                  </Link>
                </Col>
              ))
            )}

          </Row>
        </Container>
      </section>



      <section className="get-touch-section">
        <div className="section-heading mb-0">
          <h2>Ready to share ? Upload now</h2>
          <p>If you already have a recorded  testimony video, Please upload it here</p>
          <button onClick={() => navigate('/upload')}>Upload video now</button>
        </div>
      </section>

      {/* <section>
        <div className="section-heading text-center my-5">
          <p>
            As a thank you, we send you a free digital book <br />
            “Timeless Testimonies Retold”
          </p>
        </div>
      </section> */}

      <Footer />
    </div>
  );
};

export default Hashtags;
