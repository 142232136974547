import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import {
  Accordion,
  Button,
  Col,
  Container,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import Background from "../assets/images/background-patterns.svg";
import Join2 from "../assets/images/join-2.png";
import Mail from "../assets/images/mail-icon.svg";
import Call from "../assets/images/ca1l-icon.svg";
import Footer from "../Component/Footer";
import { serverApi } from "../config";
import axios from "axios";

const HelpDesk = () => {
  const [faqList, setFaqList] = useState([]);

  useEffect(() => {
    getFaq();
  }, [])

  async function getFaq() {
    try {
      const response = await axios.get(`${serverApi}/web/get_faq_with_category`);
      if (response.data.status === "success") {
        setFaqList(response.data.data);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <div>
      <Header />
      <img className="vatro-img" src={Background} />
      <section className=" hero-section-2">
        <Container>
          <div className="section-heading text-center">
            <h2>Help Desk</h2>
            <p>
              Welcome to the ShareHeart Help Desk! We're dedicated to ensuring
              you have the best experience possible <br /> on our platform.
              Below, you'll find resources and answers to our most frequently
              asked questions, guidance
              <br /> on common issues, and how to contact support for further
              assistance.
            </p>
          </div>
        </Container>
      </section>
      <section className="faq-section pt-0">
        <Container>
          <div className="section-heading text-center">
            <h2>Frequently Asked Questions (FAQs) </h2>
          </div>
          <div className="faq-tbs">
            <Tabs
              defaultActiveKey={0}
              id="uncontrolled-tab-example"
              className=""
            >
              {faqList.map((item, i) => {
                return (
                  <Tab eventKey={i} title={item.name}>
                    <Accordion defaultActiveKey={0}>
                      {item.faqData.map((faq, j) => {
                        return (
                          <Accordion.Item eventKey={j}>
                            <Accordion.Header>{j+1}. {faq.question}</Accordion.Header>
                            <Accordion.Body>
                              <p>{faq.answer}</p>
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      })}
                      {/* <Accordion.Item eventKey="0">
                        <Accordion.Header>1. How do I create an account in the app ?</Accordion.Header>
                        <Accordion.Body>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                            ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            Duis aute irure dolor in reprehenderit in voluptate velit
                            esse cillum dolore eu fugiat nulla pariatur. Excepteur
                            sint occaecat cupidatat non proident, sunt in culpa qui
                            officia deserunt mollit anim id est laborum.</p>
                        </Accordion.Body>
                      </Accordion.Item> */}
                      {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>2. What are the guidelines for uploading a testimony?</Accordion.Header>
                        <Accordion.Body>
                          <p>
                            We encourage authenticity and respect in all testimonies. Your video should be:</p>
                          <ul>

                            <li> In MP4 format and no longer than 10 minutes.</li>
                            <li>  Focused on your personal faith journey and how it has impacted your life.</li>
                            <li> Respectful of others’ beliefs and experiences.</li>
                            <li>  Free from explicit language, hate speech, and political endorsements.</li>

                          </ul>
                        </Accordion.Body>
                      </Accordion.Item> */}
                    </Accordion>
                  </Tab>
                )
              })}
              {/* <Tab eventKey="home" title="Getting Started">
                <Accordion defaultActiveKey="1">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>1. How do I create an account in the app ?</Accordion.Header>
                    <Accordion.Body>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit
                        esse cillum dolore eu fugiat nulla pariatur. Excepteur
                        sint occaecat cupidatat non proident, sunt in culpa qui
                        officia deserunt mollit anim id est laborum.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>2. What are the guidelines for uploading a testimony?</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        We encourage authenticity and respect in all testimonies. Your video should be:</p>
                      <ul>

                        <li> In MP4 format and no longer than 10 minutes.</li>
                        <li>  Focused on your personal faith journey and how it has impacted your life.</li>
                        <li> Respectful of others’ beliefs and experiences.</li>
                        <li>  Free from explicit language, hate speech, and political endorsements.</li>

                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Tab>
              <Tab eventKey="profile" title="Account Management">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>1. How do I create an account in the app ?</Accordion.Header>
                    <Accordion.Body>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit
                        esse cillum dolore eu fugiat nulla pariatur. Excepteur
                        sint occaecat cupidatat non proident, sunt in culpa qui
                        officia deserunt mollit anim id est laborum.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>2. What are the guidelines for uploading a testimony?</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        We encourage authenticity and respect in all testimonies. Your video should be:</p>
                      <ul>

                        <li> In MP4 format and no longer than 10 minutes.</li>
                        <li>  Focused on your personal faith journey and how it has impacted your life.</li>
                        <li> Respectful of others’ beliefs and experiences.</li>
                        <li>  Free from explicit language, hate speech, and political endorsements.</li>

                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Tab>
              <Tab eventKey="contact" title="Video Testimonies">
                <Accordion defaultActiveKey="1">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>1. How do I create an account in the app ?</Accordion.Header>
                    <Accordion.Body>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit
                        esse cillum dolore eu fugiat nulla pariatur. Excepteur
                        sint occaecat cupidatat non proident, sunt in culpa qui
                        officia deserunt mollit anim id est laborum.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>2. What are the guidelines for uploading a testimony?</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        We encourage authenticity and respect in all testimonies. Your video should be:</p>
                      <ul>

                        <li> In MP4 format and no longer than 10 minutes.</li>
                        <li>  Focused on your personal faith journey and how it has impacted your life.</li>
                        <li> Respectful of others’ beliefs and experiences.</li>
                        <li>  Free from explicit language, hate speech, and political endorsements.</li>

                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Tab>
              <Tab eventKey="troubleshooting" title="Troubleshooting">
                <Accordion defaultActiveKey="1">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>1. How do I create an account in the app ?</Accordion.Header>
                    <Accordion.Body>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit
                        esse cillum dolore eu fugiat nulla pariatur. Excepteur
                        sint occaecat cupidatat non proident, sunt in culpa qui
                        officia deserunt mollit anim id est laborum.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>2. What are the guidelines for uploading a testimony?</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        We encourage authenticity and respect in all testimonies. Your video should be:</p>
                      <ul>

                        <li> In MP4 format and no longer than 10 minutes.</li>
                        <li>  Focused on your personal faith journey and how it has impacted your life.</li>
                        <li> Respectful of others’ beliefs and experiences.</li>
                        <li>  Free from explicit language, hate speech, and political endorsements.</li>

                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Tab> */}
            </Tabs>
          </div>
        </Container>
      </section>


      <section className="how-reach-section cntct-section">
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <h2 className="mb-2">Contact Support</h2>
              <p className="mb-4">If you didn't find the answer to your question or need additional assistance, our support team is here to help.</p>
            </Col>
            <Col md={6}>
              <div className="reach-box">
                <div className="img-each">
                  <img className="img-fluid" src={Mail} />
                </div>
                <h3>
                  <span>
                    For support inquiries, email us at:
                  </span>
                  <Link to="/">Hello@shareheart.com</Link>
                </h3>

              </div>
            </Col>
            <Col md={6}>
              <div className="reach-box">
                <div className="img-each">
                  <img className="img-fluid" src={Call} />
                </div>
                <h3>
                  <span>
                    Call us @
                    Monday through Friday from 9 AM to 5 PM Central time
                  </span>
                  <Link to="/">+1 (914) 282 9994</Link>
                </h3>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default HelpDesk;
