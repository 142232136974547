import React, { useState } from 'react';
import { FacebookShareButton, LinkedinShareButton, WhatsappShareButton } from 'react-share';
import { FacebookIcon, WhatsappIcon } from 'react-share';
import Share from "../assets/images/share.svg";
import Youtube from "../assets/images/youtube.svg";
import Facebbok from "../assets/images/logo-facebook.svg";
import Instagram from "../assets/images/logo-instagram.svg";
import whatsapp from "../assets/images/whatsapp.svg";
import Tiktok from "../assets/images/tiktok.svg";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

const ShareVideo = ({ videoUrl }) => {
  const [showShareOptions, setShowShareOptions] = useState(false);
  const shareUrl = videoUrl; // The URL of the video to be shared

  return (
    // <div className='share-video-mange'>

    //   {showShareOptions && (
    //     <div>
    //       <FacebookShareButton url={shareUrl}>
    //         <FacebookIcon size={32} round />
    //       </FacebookShareButton>

    //       <WhatsappShareButton url={shareUrl}>
    //         <WhatsappIcon size={32} round />
    //       </WhatsappShareButton>
    //     </div>
    //   )}
    //   <img className="img-fluid" src={Share} onClick={handleShareClick} />
    // </div>
    <ul className="social-link share-mange">
      <li>
        <FacebookShareButton url={shareUrl}>
          <img src={Facebbok} />
        </FacebookShareButton>
        {/* <Link target="blank" to="https://www.youtube.com/@ShareHeartApp">
          <img src={Youtube} />
        </Link> */}
      </li>
      <li>
        <WhatsappShareButton url={shareUrl}>
          <img src={whatsapp} />
        </WhatsappShareButton>
      </li>
      {/* <li>
        <Link target="blank" to={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}>
          <img src={Facebbok} />
        </Link>
      </li> */}
      {/* <li>
        <Link target="blank" to={`whatsapp://send?text=Check%20out%20this%20awesome%20website!%20${shareUrl}`}>
          <img src={whatsapp} />
        </Link>
      </li> */}
      {/* <LinkedinShareButton url={shareUrl}>
        <img src={Instagram} />
      </LinkedinShareButton> */}
      {/* <li>
        <Link target="blank" to="https://www.tiktok.com/@shareheart.com">
          <img src={Tiktok} />
        </Link>
      </li> */}
    </ul>
  );
};

export default ShareVideo;
