import React, { useState } from "react";
import Header from "../Component/Header";
import { Accordion, Button, Col, Container, Row, Alert } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import Background from "../assets/images/background-patterns.svg";
import Mail from "../assets/images/mail-icon.svg";
import Call from "../assets/images/ca1l-icon.svg";
import Facebook from "../assets/images/facebook-1.svg";
import Tiktok from "../assets/images/tiktok-1.svg";
import Insta from "../assets/images/insta-1.svg";
import Youtube from "../assets/images/youtube-1.svg";

import Footer from "../Component/Footer";
import { serverApi } from "../config";
import axios from "axios";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: ""
  });

  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      try {
        // Clear any previous errors
        setFormErrors({});
        const response = await axios.post(`${serverApi}/add_contact_us`, new URLSearchParams(formData));
        if (response.data.status === "success") {
          setSuccessMessage("Form submitted successfully!");
          setFormData({
            name: "",
            email: "",
            phoneNumber: "",
            message: ""
          });
        } else {
          setErrorMessage("Something went wrong!");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setErrorMessage("Something went wrong!");
      }
    } else {
      setFormErrors(errors);
    }
    setTimeout(() => {
      setSuccessMessage("");
      setErrorMessage("");
    }, 5000); // Remove success message after 30 seconds
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.name.trim()) {
      errors.name = "Name is required";
    }
    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
      errors.email = "Invalid email address";
    }
    if (!data.phoneNumber.trim()) {
      errors.phoneNumber = "Phone number is required";
    } else if (!/^\+?\d{0,3}[- ]?\d{3}[- ]?\d{3}[- ]?\d{4}$/.test(data.phoneNumber)) {
      errors.phoneNumber = "Invalid phone number format";
    }
    if (!data.message.trim()) {
      errors.message = "Message is required";
    }
    return errors;
  };
  return (
    <div>
      <Header />
      <img className="vatro-img" src={Background} />
      <section className=" hero-section-2">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} md={12}>
              <div className="section-heading text-center">
                <h2>Contact Us</h2>
                <p>
                  We at ‘ShareHeart’ digital platform are here to support you on
                  your  journey of christian faith sharing and discovery.
                  Whether you have questions, need assistance, or simply want to
                  share your thoughts with us, we're here to listen and help.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="how-reach-section">
        <Container>
          <Row>
            <Col md={12}>
              <h2>How to Reach Us</h2>
            </Col>
            <Col md={6}>
              <div className="reach-box">
                <div className="img-each">
                  <img className="img-fluid" src={Mail} />
                </div>
                <h3>
                  Mail Us{" "}
                  <span>
                    For support, feedback, or inquiries, <br /> We aim to
                    respond to all emails <br /> within 48 hours.
                  </span>
                </h3>
                <Link to="mailto:Hello@shareheart.com">Hello@shareheart.com</Link>
              </div>
            </Col>
            <Col md={6}>
              <div className="reach-box">
                <div className="img-each">
                  <img className="img-fluid" src={Call} />
                </div>
                <h3>
                  Call Us{" "}
                  <span>
                    Need to speak with someone directly?
                    <br /> Our team is available Monday through
                    <br /> Friday, 9 AM to 5 PM CT
                  </span>
                </h3>
                <Link to="tel:+19142829994">+1 (914) 282 9994</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="social-media-section">
        <Container>
          <div className="section-heading text-center">
            <h2>Connect on Social Media</h2>
            <p>
              Follow us on our social media platforms to stay updated on the
              latest news, inspirational stories, and community highlights.
            </p>
          </div>
          <Row>
            <Col xxl={3} lg={4} md={6}>
              <div className="social-box">
                <a target="blank" href="https://www.facebook.com/profile.php?id=100094662100520">
                  <div className="social-name">
                    <img src={Facebook} />
                    <h3>
                      Facebook <span>Social</span>
                    </h3>
                  </div>
                </a>
                {/* <p>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. has roots in a piece.
                </p>
                <Link to="/">View Profile</Link> */}
              </div>
            </Col>
            <Col xxl={3} lg={4} md={6}>
              <div className="social-box">
                <a target="blank" href="https://www.youtube.com/@ShareHeartApp">
                  <div className="social-name">
                    <img src={Youtube} />
                    <h3>
                      Youtube <span>Social</span>
                    </h3>
                  </div>
                </a>
                {/* <p>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. has roots in a piece.
                </p>
                <Link to="/">View Profile</Link> */}
              </div>
            </Col>
            <Col xxl={3} lg={4} md={6}>
              <div className="social-box">
                <a target="blank" href="https://www.instagram.com/shareheartapp">
                  <div className="social-name">
                    <img src={Insta} />
                    <h3>
                      Instagram <span>Social</span>
                    </h3>
                  </div>
                </a>
                {/* <p>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. has roots in a piece.
                </p>
                <Link to="/">View Profile</Link> */}
              </div>
            </Col>
            <Col xxl={3} lg={4} md={6}>
              <div className="social-box">
                <a target="blank" href="https://www.tiktok.com/@shareheart.com">
                  <div className="social-name">
                    <img src={Tiktok} />
                    <h3>
                      Tiktok <span>Social</span>
                    </h3>
                  </div>
                </a>
                {/* <p>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. has roots in a piece.
                </p>
                <Link to="/">View Profile</Link> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="feedback-section">
        <Container>
          <div className="section-heading text-center">
            <h2>Feedback and Suggestions</h2>
            <p>
              Your feedback is invaluable to us. If you have suggestions on how
              we can improve our services or want to <br /> share what you love about
              ShareHeart, don't hesitate to reach out.  We're continually looking<br />
              to enhance our platform and services to better serve our
              community.
            </p>
          </div>
          <div className="from-feedback">
            {successMessage && (
              <Alert variant="success" onClose={() => setSuccessMessage("")} dismissible>
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
              </Alert>
            )}
            <form className="cmn-frm" onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <label>Full name</label>
                    <input
                      type="text"
                      placeholder="John David"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {formErrors.name && (
                      <p className="text-danger">{formErrors.name}</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label>Email Address</label>
                    <input
                      type="text"
                      placeholder="example@yourmail.com"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {formErrors.email && (
                      <p className="text-danger">{formErrors.email}</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label>Phone number</label>
                    <input
                      type="text"
                      placeholder="+1 914 282 9994"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                    {formErrors.phoneNumber && (
                      <p className="text-danger">{formErrors.phoneNumber}</p>
                    )}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-group">
                    <label>Comments / Questions / Feedback</label>
                    <textarea
                      placeholder="Hello there"
                      rows="5"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                    {formErrors.message && (
                      <p className="text-danger">{formErrors.message}</p>
                    )}
                  </div>
                </Col>
                <Col md={12} className="text-center">
                  <Button className="cmn-btn rounded-pill px-5" type="submit">Submit</Button>
                </Col>
              </Row>
            </form>
          </div>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default ContactUs;
