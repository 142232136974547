import React from "react";
import Header from "../Component/Header";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import Background from "../assets/images/background-patterns.svg";
import About1 from "../assets/images/about-img.png";
import About2 from "../assets/images/about-img-2.png";
import Storyimg from "../assets/images/story-img.png";
import Icon1 from "../assets/images/icon-1.svg";
import Icon2 from "../assets/images/icon-2.svg";
import Icon3 from "../assets/images/icon-3.svg";
import Icon4 from "../assets/images/icon-4.svg";
import Join1 from "../assets/images/join-img.png";
import Join2 from "../assets/images/join-2.png";

import Footer from "../Component/Footer";

const AboutUs = () => {
  return (
    <div>
      <Header />
      <img className="vatro-img" src={Background} />
      <section className=" hero-section-2">
        <Container>
          <div className="section-heading text-center">
            <h2>About Us</h2>
          </div>
          <Row>
          <Col xs={12} >
              <div className="section-heading one-line-text">
                <h2>Our Mission</h2>
                <p>
                  Hello Friends ! We at ‘ShareHeart’ Digital Platform deeply
                  believe in how God's love and our faith can truly change
                  things around us. Our big goal is to create a special spot
                  online where people from all corners of the Earth can gather
                  to share and listen to real-life stories about walking with
                  God. It's all about making a family-like community filled with
                  support, joy, and growing closer to God together.
                </p>
              </div>
            </Col>
            <Col md={8}>
              <div className="abt-img-amin">
                <img className="img-fluid" src={About1} />
              </div>
            </Col>
            <Col md={4} >
              <div className="abt-img-amin">
                <img className="img-fluid" src={About2} />
              </div>
            </Col>
          
          </Row>
        </Container>
      </section>

      <section className="our-story text-center">
        <Container>
          <Row>
            
            <Col md={12}>
              <div className="section-heading ">
                <h2>Our Story</h2>
                <p>
                  ‘ShareHeart’ started with a simple, yet powerful thought:
                  every single story of someone's faith journey has this
                  incredible power to reach out and touch someone's heart. It
                  can change lives and, most importantly, help us all feel a bit
                  closer to God. With everyone glued to their screens these
                  days, we figured, why not make a space that uses that tech for
                  something good, like keeping the tradition of sharing stories
                  about God alive? Now, our platform is blossoming into this
                  beautiful community of believers, each sharing their own
                  unique tales of faith. It’s truly inspiring to see the bravery
                  and love in each story shared with us.
                </p>
              </div>
            </Col>
            <Col md={12} >
              <div className="story-img">
                <img className="img-fluid" src={Storyimg} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="our-vision-section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} md={12}>
              <div className="section-heading text-center">
                <h2>Our Vision</h2>
                <p>
                  What we believe for ‘ShareHeart’ is more than just a website
                  or an app; it's a worldwide family where sharing our walks
                  with God helps us understand each other better, brings us
                  closer, and shows us we're all part of something bigger. We're
                  excited to keep growing, bringing more stories into the light,
                  and showing how God's love works in so many ways.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="our-values">
        <Container>
          <div className="section-heading text-center">
            <h2>Our Values</h2>
          </div>
          <Row>
            <Col md={6}>
              <div className="values-box">
                <div className="icon-img">
                  <img className="img-fluid" src={Icon2} />
                </div>
                <div>
                  <h3>Community</h3>
                  <p>
                    The biggest thing for us is creating a welcoming space where
                    everyone can share, learn, and grow together, all wrapped up
                    in God’s love.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="values-box">
                <div className="icon-img">
                  <img className="img-fluid" src={Icon4} />
                </div>
                <div>
                  <h3>Inspiration</h3>
                  <p>
                    We’re here to cheer you on and lift you up, showing through
                    stories that no matter where you are in your journey, God is
                    always working in your life.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="values-box">
                <div className="icon-img">
                  <img className="img-fluid" src={Icon3} />
                </div>
                <div>
                  <h3>Honesty</h3>
                  <p>
                    We aim to keep it real, sharing true stories that honor God
                    and build genuine connections.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="values-box">
                <div className="icon-img">
                  <img className="img-fluid" src={Icon1} />
                </div>
                <div>
                  <h3>Innovation</h3>
                  <p>
                    We’re all about using the coolest tech to make sharing and
                    finding stories about God easy and meaningful for everyone.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="joinus-section">
        <Container>
          <Row>
            <Col md={7}>
              <div className="section-heading">
                <h2>Join Us</h2>
                <p>
                  So, whether you’re keen to share how God has moved in your
                  life, looking for a bit of inspiration, or just curious about
                  all the ways God shows up for us, ShareHeart is your spot.
                  Let’s come together to celebrate the amazing ways God brings
                  us together, helps us heal, and sparks inspiration in our
                  hearts. Thanks for checking us out. With God guiding us, we’re
                  all in this together – welcome to the ShareHeart family!
                </p>
              </div>
              <div className="join-img-img">
                <img className="img-fluid" src={Join1} />
              </div>
            </Col>
            <Col md={5}>
            <div className="join-img">
                <img className="img-fluid" src={Join2} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </div>
  );
};

export default AboutUs;
