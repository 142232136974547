import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import Logo from "../assets/images/logo.svg";
import Youtube from "../assets/images/youtube.svg";
import Facebbok from "../assets/images/logo-facebook.svg";
import Instagram from "../assets/images/logo-instagram.svg";
import Tiktok from "../assets/images/tiktok.svg";

const Footer = () => {
  return (
    <div>
      <footer>
        <Container>
          <Row>
            <Col lg={6} md={4}>
              <div className="ftr">
                <Link to="/">
                  <img className="img-fluid mb-4" src={Logo} />
                </Link>
                <h2 className="mb-5">
                At ShareHeart digital platform, We're on a mission to spread hope and encouragement through  the sharing of  Christian faith testimonies  form <br/> across the world
                </h2>
                <ul className="social-link">
                  <li>
                    <Link target="blank" to="https://www.youtube.com/@ShareHeartApp">
                      <img src={Youtube} />
                    </Link>
                  </li>
                  <li>
                    <Link target="blank" to="https://www.facebook.com/profile.php?id=100094662100520">
                      <img src={Facebbok} />
                    </Link>
                  </li>
                  <li>
                    <Link target="blank" to="https://www.instagram.com/shareheartapp">
                      <img src={Instagram} />
                    </Link>
                  </li>
                  <li>
                    <Link target="blank" to="https://www.tiktok.com/@shareheart.com">
                      <img src={Tiktok} />
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col >
              {/* <h2>Company</h2> */}
              <ul className="use-full-link">
                <li>
                  <Link to="/about">About us </Link>
                </li>
                <li>
                  <Link to="/contact">Contact us </Link>
                </li>
                <li>
                  <Link to="/help-desk">Help desk</Link>
                </li>
              </ul>
            </Col>
            <Col >
              {/* <h2>Legal</h2> */}
              <ul className="use-full-link">
                <li>
                  <Link to="/volunteer">Volunteer </Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy </Link>
                </li>
                <li>
                  <Link to="/terms-service">Terms & Conditions</Link>
                </li>
              </ul>
            </Col>
            <Col md={12}>
              <div className="bottom-footer">
                <p>© 2024 Copyright, All Right Reserved</p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;
