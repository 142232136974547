// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import Header from "../Component/Header";
// import Footer from '../Component/Footer';
// import { Alert } from "react-bootstrap";
// import { serverApi } from '../config';

// const Login = () => {
//     const navigate = useNavigate();
//     const [email, setEmail] = useState('');
//     const [otp, setOtp] = useState('');
//     const [showOtpField, setShowOtpField] = useState(false);
//     const [timer, setTimer] = useState(60);
//     const [resendDisabled, setResendDisabled] = useState(false);
//     const [successMessage, setSuccessMessage] = useState("");
//     const [errorMessage, setErrorMessage] = useState("");
//     const [emailError, setEmailError] = useState("");
//     const [otpError, setOtpError] = useState("");
//     const [otpSent, setOtpSent] = useState(false); // Track whether OTP has been sent
//     const [otpExpired, setOtpExpired] = useState(false); // Flag to track OTP expiration

//     useEffect(() => {
//         let intervalId;

//         if (showOtpField && timer > 0) {
//             intervalId = setInterval(() => {
//                 setTimer((prevTimer) => prevTimer - 1);
//             }, 1000);
//         }

//         return () => {
//             clearInterval(intervalId);
//         };
//     }, [showOtpField, timer]);

//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//     const handleGetOtp = async () => {
//         if (!email.trim()) {
//             setEmailError("Email is required");
//             return;
//         } else if (!emailRegex.test(email.trim())) {
//             setEmailError("Invalid email format");
//             return;
//         } else {
//             setEmailError("");
//         }

//         try {
//             const response = await axios.post(`${serverApi}/generate-otp`, new URLSearchParams({ email }));
//             console.log(response.data); // Assuming backend responds with status

//             setShowOtpField(true);
//             startTimer();
//             setOtpSent(true); // Set OTP sent state to true
//         } catch (error) {
//             setErrorMessage(error.response.data.message);
//         }
//     };

//     const startTimer = () => {
//         setTimer(60);
//         const intervalId = setInterval(() => {
//             setTimer((prevTimer) => prevTimer - 1);
//         }, 1000);
//         setTimeout(() => {
//             clearInterval(intervalId);
//             setOtpExpired(true); // Mark OTP as expired
//         }, 60000); // Stop timer after 60 seconds
//     };

//     const handleResendOtp = async () => {
//         if (!email.trim()) {
//             setEmailError("Email is required");
//             return;
//         } else if (!emailRegex.test(email.trim())) {
//             setEmailError("Invalid email format");
//             return;
//         } else {
//             setEmailError("");
//         }


//         try {
//             const response = await axios.post(`${serverApi}/generate-otp`, new URLSearchParams({ email }));

//             if (response.data.status === "success") {
//                 setSuccessMessage(response.data.message);
//                 setTimer(60); // Reset timer
//                 setOtpExpired(false); // Reset OTP expiration flag
//                 startTimer(); // Start timer again
//                 setTimeout(() => {
//                     setSuccessMessage("");
//                 }, 30000); // Remove success message after 30 seconds
//             } else {
//                 setErrorMessage(response.data.message);
//             }
//         } catch (error) {
//             setErrorMessage(error.response.data.message);
//             console.error('Error resending OTP:', error);
//         }
//     };

//     const handleLogin = async () => {
//         if (!otp.trim()) {
//             setOtpError("OTP is required");
//             return;
//         } else if (otp.trim().length !== 6 || isNaN(otp.trim())) {
//             setOtpError("OTP must be a 6-digit number");
//             return;
//         } else {
//             setOtpError("")
//         }

//         try {
//             // Send request to backend to verify OTP and login
//             const response = await axios.post(`${serverApi}/verify-otp`, new URLSearchParams({ email, otp }));
//             console.log(response.data);
//             if (response.data.status === "success") {
//                 setSuccessMessage(response.data.message);
//                 localStorage.setItem('userId', response.data.data._id);
//                 localStorage.setItem('firstName', response.data.data.firstName);
//                 localStorage.setItem('lastName', response.data?.data?.lastName === undefined ? '' : response.data?.data?.lastName);
//                 localStorage.setItem('email', response.data.data.local.email);
//                 localStorage.setItem('permission', response.data.data.userType);
//                 localStorage.setItem('image', response.data.data.image);
//                 navigate('/')
//             } else {
//                 setErrorMessage(response.data.message);
//             }
//         } catch (error) {
//             setErrorMessage(error.response.data.message);
//         }
//     };

//     return (
//         <div>
//             <Header />
//             <div className="container">
//                 <div className="row justify-content-center align-items-center min-vh-100">
//                     <div className="col-md-6">
//                         <div className="card">
//                             <div className="card-body">
//                                 <h1 className="card-title text-center">Login</h1>
//                                 {successMessage && (
//                                     <Alert variant="success" onClose={() => setSuccessMessage("")} dismissible>
//                                         {successMessage}
//                                     </Alert>
//                                 )}
//                                 {errorMessage && (
//                                     <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
//                                         {errorMessage}
//                                     </Alert>
//                                 )}
//                                 <div className="mb-3">
//                                     <input
//                                         type="email"
//                                         className={`form-control ${emailError ? 'is-invalid' : ''}`}
//                                         placeholder="Enter your email"
//                                         value={email}
//                                         onChange={(e) => setEmail(e.target.value)}
//                                     />
//                                     {emailError && <div className="invalid-feedback">{emailError}</div>}
//                                 </div>
//                                 {!showOtpField && (
//                                     <div className="mb-3">
//                                         <button className="btn btn-primary w-100" onClick={handleGetOtp}>Get OTP</button>
//                                     </div>
//                                 )}
//                                 {showOtpField && (
//                                     <>
//                                         <div className="mb-3">
//                                             <input
//                                                 type="text"
//                                                 className={`form-control ${otpError ? 'is-invalid' : ''}`}
//                                                 placeholder={`Enter OTP (${timer})`}
//                                                 value={otp}
//                                                 onChange={(e) => setOtp(e.target.value)}
//                                             />
//                                             {otpError && <div className="invalid-feedback">{otpError}</div>}
//                                         </div>
//                                         {otpExpired ? (
//                                             <div className="mb-3">
//                                                 <button className="btn btn-danger w-100" onClick={handleResendOtp}>Resend OTP</button>
//                                             </div>
//                                         ) : (
//                                             <div className="mb-3">
//                                                 <button className="btn btn-success w-100" onClick={handleLogin}>Login</button>
//                                             </div>
//                                         )}
//                                     </>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </div>
//     );
// };

// export default Login;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from '../Component/Footer';
import { Alert } from "react-bootstrap";
import { serverApi } from '../config';
import Logo from '../assets/images/logo.svg'

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [showOtpField, setShowOtpField] = useState(false);
    const [timer, setTimer] = useState(60);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [emailError, setEmailError] = useState("");
    const [otpError, setOtpError] = useState("");
    const [otpSent, setOtpSent] = useState(false); // Track whether OTP has been sent
    const [otpExpired, setOtpExpired] = useState(false); // Flag to track OTP expiration

    useEffect(() => {
        let intervalId;

        if (showOtpField && timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [showOtpField, timer]);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleGetOtp = async () => {
        if (!email.trim()) {
            setEmailError("Email is required");
            return;
        } else if (!emailRegex.test(email.trim())) {
            setEmailError("Invalid email format");
            return;
        } else {
            setEmailError("");
        }
        setResendDisabled(true);
        try {
            const response = await axios.post(`${serverApi}/generate-otp`, new URLSearchParams({ email }));
            console.log(response.data); // Assuming backend responds with status

            setShowOtpField(true);
            startTimer();
            setOtpSent(true); // Set OTP sent state to true
            setResendDisabled(false); // Disable the "Get OTP" button after sending
        } catch (error) {
            setErrorMessage(error.response.data.message);
            setResendDisabled(false);
        }
        setTimeout(() => {
            setSuccessMessage("");
            setErrorMessage("");
        }, 5000); // Remove success message after 30 seconds
    };

    const startTimer = () => {
        setTimer(60);
        const intervalId = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
        setTimeout(() => {
            clearInterval(intervalId);
            setOtpExpired(true); // Mark OTP as expired
            setResendDisabled(false); // Enable the "Resend OTP" button after expiration
        }, 60000); // Stop timer after 60 seconds
    };

    const handleResendOtp = async () => {
        setErrorMessage("")
        setSuccessMessage("")
        if (!email.trim()) {
            setEmailError("Email is required");
            return;
        } else if (!emailRegex.test(email.trim())) {
            setEmailError("Invalid email format");
            return;
        } else {
            setEmailError("");
        }
        setResendDisabled(true);
        try {
            const response = await axios.post(`${serverApi}/generate-otp`, new URLSearchParams({ email }));

            if (response.data.status === "success") {
                setSuccessMessage(response.data.message);
                setTimer(60); // Reset timer
                setOtpExpired(false); // Reset OTP expiration flag
                startTimer(); // Start timer again
                setResendDisabled(false); // Disable the "Resend OTP" button after resending

            } else {
                setErrorMessage(response.data.message);
                setResendDisabled(false);
            }
        } catch (error) {
            setErrorMessage(error.response.data.message);
            setResendDisabled(false);
            console.error('Error resending OTP:', error);
        }
        setTimeout(() => {
            setSuccessMessage("");
            setErrorMessage("");
        }, 5000); // Remove success message after 30 seconds
    };

    const handleLogin = async () => {
        setErrorMessage("")
        setSuccessMessage("")
        if (!otp.trim()) {
            setOtpError("OTP is required");
            return;
        } else if (otp.trim().length !== 6 || isNaN(otp.trim())) {
            setOtpError("OTP must be a 6-digit number");
            return;
        } else {
            setOtpError("")
        }

        try {
            // Send request to backend to verify OTP and login
            const response = await axios.post(`${serverApi}/verify-otp`, new URLSearchParams({ email, otp }));

            if (response.data.status === "success") {
                setSuccessMessage(response.data.message);
                localStorage.setItem('userId', response.data.data._id);
                localStorage.setItem('firstName', response.data.data.firstName);
                localStorage.setItem('lastName', response.data?.data?.lastName === undefined ? '' : response.data?.data?.lastName);
                localStorage.setItem('email', response.data.data.local.email);
                localStorage.setItem('permission', response.data.data.userType);
                localStorage.setItem('image', response.data.data.image);
                navigate('/')
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            setErrorMessage(error.response.data.message);
        }
        setTimeout(() => {
            setSuccessMessage("");
            setErrorMessage("");
        }, 5000); // Remove success message after 30 seconds
    };

    return (
        <div>
            <Header />
            <div className="container">
                <div className="row justify-content-center align-items-center min-vh-100">
                    <div className="col-md-6">
                        <div className="card login-form-section">
                            <div className='login-logo text-center'>
                                <img src={Logo} />
                            </div>
                            <div className="card-body">
                                <h1 className="card-title text-center">Login</h1>
                                {successMessage && (
                                    <Alert variant="success" onClose={() => setSuccessMessage("")} dismissible>
                                        {successMessage}
                                    </Alert>
                                )}
                                {errorMessage && (
                                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                                        {errorMessage}
                                    </Alert>
                                )}
                                <div className="mb-3">
                                    <input
                                        type="email"
                                        className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {emailError && <div className="invalid-feedback">{emailError}</div>}
                                </div>
                                {!showOtpField && (
                                    <div className="mb-3">
                                        <button className="btn cmn-btn w-100" onClick={handleGetOtp} disabled={resendDisabled}>Get OTP</button>
                                    </div>
                                )}
                                {showOtpField && (
                                    <>
                                        <div className="mb-3">
                                            <input
                                                type="text"
                                                className={`form-control ${otpError ? 'is-invalid' : ''}`}
                                                placeholder={`Enter OTP (${timer})`}
                                                value={otp}
                                                onChange={(e) => setOtp(e.target.value)}
                                            />
                                            {otpError && <div className="invalid-feedback">{otpError}</div>}
                                        </div>
                                        {otpExpired ? (
                                            <div className="mb-3">
                                                <button className="btn btn-danger w-100" onClick={handleResendOtp} disabled={resendDisabled}>Resend OTP</button>
                                            </div>
                                        ) : (
                                            <div className="mb-3">
                                                <button className="btn btn-success w-100" onClick={handleLogin}>Login</button>
                                            </div>
                                        )}
                                    </>
                                )}
                                <div className='row'>
                                    <div class="col-md-12">
                                        <p style={{ "textAlign": "center" }}>Don’t have an account? <Link style={{ "color": "darkblue" }} to="/signup">Sign up here</Link> to get started</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Login;
