import React, { useState } from "react";
import Header from "../Component/Header";
import { Accordion, Button, Col, Container, Row, Alert } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import Background from "../assets/images/background-patterns.svg";
import Googleplay from "../assets/images/google-play.svg";
import Appstore from "../assets/images/app-store.svg";
import Appscreen from "../assets/images/app-screen.png";
import Footer from "../Component/Footer";
import { serverApi } from "../config";
import Videoman from "../assets/images/video-man.gif";
import axios from "axios";

const InstallApp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: ""
  });

  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      try {
        // Clear any previous errors
        setFormErrors({});
        const response = await axios.post(`${serverApi}/add_contact_us`, new URLSearchParams(formData));
        if (response.data.status === "success") {
          setSuccessMessage("Form submitted successfully!");
          setFormData({
            name: "",
            email: "",
            phoneNumber: "",
            message: ""
          });
          setTimeout(() => {
            setSuccessMessage("");
          }, 30000); // Remove success message after 30 seconds
        } else {
          setErrorMessage("Something went wrong!");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setErrorMessage("Something went wrong!");
      }
    } else {
      setFormErrors(errors);
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.name.trim()) {
      errors.name = "Name is required";
    }
    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
      errors.email = "Invalid email address";
    }
    if (!data.phoneNumber.trim()) {
      errors.phoneNumber = "Phone number is required";
    } else if (!/^\+?\d{0,3}[- ]?\d{3}[- ]?\d{3}[- ]?\d{4}$/.test(data.phoneNumber)) {
      errors.phoneNumber = "Invalid phone number format";
    }
    if (!data.message.trim()) {
      errors.message = "Message is required";
    }
    return errors;
  };
  return (
    <div>
      <Header />
      <img className="vatro-img" src={Background} />
      <section className=" hero-section-2 install-app-section">
        <Container>
          <div className="outer-box ">
            <Row className="justify-content-center align-items-center">
              <Col lg={6} md={6}>
                <div className="section-heading install-shareheart">
                  <h2>Install ShareHeart App</h2>
                  <h4 >
                    Discover a community, Watch uplifting stories, Get Inspired,
                    Share your story
                  </h4>
                  <div className="app-link">
                    <Link to="https://play.google.com/store/apps/details?id=com.shareheartapp.app">
                      <img className="img-fluid" src={Googleplay} />
                    </Link>
                    <Link to="https://apps.apple.com/us/app/shareheart/id6449276730">
                      <img className="img-fluid" src={Appstore} />
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md={6} className="text-end">
                {/* <img className="img-fluid" src={Appscreen} /> */}
                <div className="video-session">
                  {/* <video className="img-fluid"  loop autoPlay muted  >
      <source src={Videoman} type="video/mp4"/>
     </video> */}
                  <img className="img-fluid" src={Videoman} />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      {/* <section className="feedback-section">
        <Container>
          <div className="from-feedback">
            {successMessage && (
              <Alert variant="success" onClose={() => setSuccessMessage("")} dismissible>
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
              </Alert>
            )}
            <form className="cmn-frm" onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <label>Full name</label>
                    <input
                      type="text"
                      placeholder="John David"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {formErrors.name && (
                      <p className="text-danger">{formErrors.name}</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label>Email Address</label>
                    <input
                      type="text"
                      placeholder="example@yourmail.com"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {formErrors.email && (
                      <p className="text-danger">{formErrors.email}</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label>Phone number</label>
                    <input
                      type="text"
                      placeholder="+1 914 282 9994"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                    {formErrors.phoneNumber && (
                      <p className="text-danger">{formErrors.phoneNumber}</p>
                    )}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-group">
                    <label>Write your testimony</label>
                    <label>
                      Please describe your real-life faith experience. This
                      could be an encounter with Jesus, <br />
                      your personal journey of transformation, or a moment that
                      was a miracle in your life.
                    </label>
                    <textarea
                      placeholder="Hello there"
                      rows="5"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                    {formErrors.message && (
                      <p className="text-danger">{formErrors.message}</p>
                    )}
                  </div>
                </Col>
                <Col md={12} className="text-center">
                  <Button className="cmn-btn rounded-pill px-5" type="submit">Submit</Button>
                </Col>
              </Row>
            </form>
          </div>
        </Container>
      </section> */}
      <Footer />
    </div>
  );
};

export default InstallApp;
