import React from "react";
import Header from "../Component/Header";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import Background from "../assets/images/background-patterns.svg";
import Mail from "../assets/images/mail-icon.svg";
import Call from "../assets/images/ca1l-icon.svg";
import Facebook from "../assets/images/facebook-1.svg";
import Tiktok from "../assets/images/tiktok-1.svg";
import Insta from "../assets/images/insta-1.svg";
import Youtube from "../assets/images/youtube-1.svg";

import Footer from "../Component/Footer";

const TermsService = () => {
  return (
    <div>
      <Header />
      <img className="vatro-img" src={Background} />
      <section className=" hero-section-2">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} md={12}>
              <div className="section-heading text-center">
                <h2>Terms of Service</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="policy-term">
        <Container>
          <div className="outr-box">
            <h2>
            Terms of Service for ShareHeart{" "}
              <span>(Effective date: April 2024)</span>
            </h2>
            <p>
              Welcome to ShareHeart, a digital platform dedicated to sharing and
              spreading the power of Christian faith through personal
              testimonies. This Privacy Policy outlines how we collect, use,
              protect, and share information about our users ("you") when you
              use our website, mobile application ("app"), and services
              (collectively, "Services").
            </p>
            <h2>1. Using ShareHeart</h2>
            <h3>a. Eligibility</h3>
            <p>
            You must be at least 13 years old to use ShareHeart. By agreeing to these Terms, you represent and warrant that you are at least 13 years old.
            </p>
            <h3>b. Account Registration</h3>
            <p>To access certain features of our Services, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
            <h3>c. User Content</h3>
            <p>You retain all rights to the testimony videos and other content you submit to ShareHeart ("User Content"). However, by submitting User Content, you grant ShareHeart a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the User Content in connection with the Services and ShareHeart's (and its successors' and affiliates') business.</p>
            <h3>d. Prohibited Conduct</h3>
            <p>You agree not to engage in any of the following prohibited activities: (i) copying, distributing, or disclosing any part of the Services in any medium; (ii) using the Services for any commercial solicitation purposes; (iii) interfering with the proper working of the Services; (iv) accessing any content on the Services through any technology or means other than those provided by ShareHeart; or (v) bypassing the measures we may use to prevent or restrict access to the Services.</p>
            <h2>2. Intellectual Property</h2>
            <p>The Services and all content contained therein, including, but not limited to, text, graphics, logos, icons, images, audio clips, digital downloads, and software, are the property of ShareHeart or its content suppliers and protected by copyright and other intellectual property laws.</p>
            <h2>3. Termination</h2>
            <p>ShareHeart may terminate or suspend your account and bar access to the Services immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
            <h2>4. Disclaimer of Warranties</h2>
            <p>The Services are provided on an "as is" and "as available" basis. ShareHeart makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property, or other violation of rights.</p>
            <h2>5. Limitation of Liability</h2>
            <p>In no event shall ShareHeart or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the Services, even if ShareHeart or a ShareHeart authorized representative has been notified orally or in writing of the possibility of such damage.
</p>
<h2>6. Changes to Terms</h2>
<p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
<h2>7. Contact Us</h2>
<p>If you have any questions about these Terms, please contact us at: Hello@shareheart.com</p>

          </div>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default TermsService;
