import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../assets/images/logo.svg'
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JavaScript
import { serverApi } from '../config';
import Avatar from "../assets/images/profile-auto.png"

const Header = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(window.location.pathname);
  // Add and Remove Class on scroll
  const [scrolltopdata, setscrolltopdata] = useState('');
  const [logged, setLogged] = useState(false);
  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');


  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.classList.remove('overflow-hidden');
    const firstName = window.localStorage.getItem('firstName');
    const lastName = window.localStorage.getItem('lastName');

    setFName(firstName);
    setLName(lastName === 'undefined' ? '' : lastName);

    const token = window.localStorage.getItem('userId');
    const permission = window.localStorage.getItem('permission');


    if (token !== null && permission === 'Member') {
      setLogged(true);
    } else {
      setLogged(false);
    }

  }, [window.location.href, logged, window.localStorage.getItem('firstName'), window.localStorage.getItem('lastName')])

  const handleLogout = async e => {
    localStorage.clear()
    navigate('/')
  }


  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 15) {
        setscrolltopdata('');
      } else {
        setscrolltopdata('fx-hdr');
      }
    });
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optionally add smooth scrolling behavior
    });
  }, [])

  return (
    <div>
      <>
        <div className={`header-wrapper ${scrolltopdata}`}>
          <Navbar expand="lg" className="header-sectin">
            {/* <Navbar expand="lg" className="header-sectin"> */}
            <Container>
              <Navbar.Brand><Link to="/" className='logo-main'><img src={Logo} /></Link></Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="ms-auto my-2 my-lg-0 menu-items"
                  navbarScroll
                >
                  <Link to="/hashtags" onClick={() => setIsActive('/hashtags')} className={isActive === '/hashtags' ? 'active' : ''}>Watch now</Link>
                  <Link to="/about" onClick={() => setIsActive('/about')} className={isActive === '/about' ? 'active' : ''}>About Us</Link>

                  <Link to="/contact" onClick={() => setIsActive('/contact')} className={isActive === '/contact' ? 'active' : ''}>Contact</Link>
                  <Link to="/volunteer" onClick={() => setIsActive('/volunteer')} className={isActive === '/volunteer' ? 'active' : ''}>Volunteer</Link>
                  <Link to="/share" onClick={() => setIsActive('/share')} className={isActive === '/share' ? 'active' : ''}> Share your story </Link>
                  <Link to="/install" onClick={() => setIsActive('/install')} className={isActive === '/install' ? 'active' : ''}> Install app</Link>
                </Nav>
                {logged ? '' :
                  <div className='desktp-none'>
                    <Link to="/login"><span>Log In</span></Link>
                  </div>
                }
                <div className='get-start-btn'>
                  <button onClick={() => navigate('/upload')} className='cmn-btn '>Get Started</button>
                </div>
                <div className='ms-4 mb-msg-0'>
                  {logged ? <div className="container-fluid profile-mange-sct">
                    <div className="navbar_profile dropdown">
                      <a href="#" className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <div className="navbar_profile_img"><img
                          src={window.localStorage.getItem('image') === '' || window.localStorage.getItem('image') === 'undefined' ? Avatar : serverApi + '/' + window.localStorage.getItem('image')}
                          alt=""
                          className="rounded-circle profile-icon-mangw"
                        /></div>
                        <div className="navbar_profile_con">
                          <p className="navbar_profile_nm">{fname} {lname}</p>
                        </div>
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><Link className="dropdown-item" to="/profile">Profile</Link></li>
                        <li onClick={handleLogout}><a className="dropdown-item" href="#">Logout</a></li>
                      </ul>
                    </div>
                  </div> :
                    <div className='mobile-none'><Link to="/login"><span>Log In</span></Link></div>
                  }
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </>
    </div >
  )
}

export default Header