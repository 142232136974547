import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../Component/Header";
import { Button, Col, Container, Row, Alert } from "react-bootstrap";
import Background from "../assets/images/background-patterns.svg";
import Footer from "../Component/Footer";
import { serverApi } from "../config";
import { useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Share = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    testimony: ""
  });

  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    AOS.init(); // Initialize AOS library
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      try {
        // Clear any previous errors
        setFormErrors({});
        const response = await axios.post(`${serverApi}/add_get_in_touch`, new URLSearchParams(formData));
        if (response.data.status === "success") {
          setSuccessMessage("Form submitted successfully!");
          setFormData({
            name: "",
            email: "",
            phoneNumber: "",
            testimony: ""
          });
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000); // Remove success message after 30 seconds
        } else {
          setErrorMessage("Something went wrong!");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setErrorMessage("Something went wrong!");
      }
    } else {
      setFormErrors(errors);
    }
    setTimeout(() => {
      setSuccessMessage("");
      setErrorMessage("");
    }, 5000); // Remove success message after 30 seconds
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.name.trim()) {
      errors.name = "Name is required";
    }
    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
      errors.email = "Invalid email address";
    }
    if (!data.phoneNumber.trim()) {
      errors.phoneNumber = "Phone number is required";
    } else if (!/^\+?\d{0,3}[- ]?\d{3}[- ]?\d{3}[- ]?\d{4}$/.test(data.phoneNumber)) {
      errors.phoneNumber = "Invalid phone number format";
    }
    if (!data.testimony.trim()) {
      errors.testimony = "Testimony is required";
    }
    return errors;
  };

  function uploadVideo() {
    navigate("/upload");
  }

  return (
    <div>
      <Header />
      <img className="vatro-img" src={Background} />
      <section className=" hero-section-2">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} md={12}>
              <div className="section-heading text-center">
                {/* <h2>Contact Us</h2> */}
                <h4>Share your story in a video call</h4>
                <p>
                  Get in touch with us for a free video recording call.
                  Our coordinators will schedule a call and talk with
                  you to discover, refine, and record your story
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="feedback-section">
        <Container>
          <div className="from-feedback">
            {successMessage && (
              <Alert variant="success" onClose={() => setSuccessMessage("")} dismissible>
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
              </Alert>
            )}
            <form className="cmn-frm" onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <label>Full name</label>
                    <input
                      type="text"
                      placeholder="John David"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {formErrors.name && (
                      <p className="text-danger">{formErrors.name}</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label>Email Address</label>
                    <input
                      type="text"
                      placeholder="example@yourmail.com"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {formErrors.email && (
                      <p className="text-danger">{formErrors.email}</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label>Phone number</label>
                    <input
                      type="text"
                      placeholder="+1 914 282 9994"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                    {formErrors.phoneNumber && (
                      <p className="text-danger">{formErrors.phoneNumber}</p>
                    )}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-group">
                    <label>Write your testimony</label>
                    <textarea
                      placeholder="Hello there"
                      rows="5"
                      name="testimony"
                      value={formData.testimony}
                      onChange={handleChange}
                    />
                    {formErrors.testimony && (
                      <p className="text-danger">{formErrors.testimony}</p>
                    )}
                  </div>
                </Col>
                <Col md={12} className="text-center">
                  <button className="cmn-btn rounded-pill px-5" type="submit">
                    Get in touch
                  </button>
                </Col>
              </Row>
            </form>
          </div>
        </Container>
      </section>
      <section className="get-touch-section">
        <div className="section-heading mb-0">
          <h2 >Ready to share ? Upload now</h2>
          <p >If you already have a recorded testimony video, Please upload it here</p>
          <button className="btsrp-btn"  onClick={uploadVideo}>Upload video now</button>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Share;
