import React, { useState, useRef } from "react";
import Header from "../Component/Header";
import { Accordion, Button, Col, Container, Row, Alert } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import Sliderimg from "../assets/images/volunteer.png";
import Videorecord from "../assets/images/video-record.svg";
import Virtualcalls from "../assets/images/virtual-calls.svg";
import Volunteerman from "../assets/images/volunteer-man.png";
import Tailright from "../assets/images/tail-right.svg";

import Footer from "../Component/Footer";
import { serverApi } from "../config";
import axios from "axios";

const Volunteer = () => {
  const elementRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: ""
  });

  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      try {
        // Clear any previous errors
        setFormErrors({});
        const response = await axios.post(`${serverApi}/add_volunteer_contact_us`, new URLSearchParams(formData));
        if (response.data.status === "success") {
          setSuccessMessage("Form submitted successfully!");
          setFormData({
            name: "",
            email: "",
            phoneNumber: "",
            message: ""
          });
        } else {
          setErrorMessage("Something went wrong!");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setErrorMessage("Something went wrong!");
      }
    } else {
      setFormErrors(errors);
    }
    setTimeout(() => {
      setSuccessMessage("");
      setErrorMessage("");
    }, 5000); // Remove success message after 30 seconds
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.name.trim()) {
      errors.name = "Name is required";
    }
    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
      errors.email = "Invalid email address";
    }
    if (!data.phoneNumber.trim()) {
      errors.phoneNumber = "Phone number is required";
    } else if (!/^\+?\d{0,3}[- ]?\d{3}[- ]?\d{3}[- ]?\d{4}$/.test(data.phoneNumber)) {
      errors.phoneNumber = "Invalid phone number format";
    }
    if (!data.message.trim()) {
      errors.message = "Message is required";
    }
    return errors;
  };

  const scrollToElement = () => {
    // Scroll to the element with the specified ID
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div>
      <Header />
      <section className="hero-section pb-0">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="">
                <h1>Join Our Mission</h1>
                <p>
                  At ShareHeart, we're on a mission to bring people together
                  through the powerful sharing of Christian faith testimonies.
                  Our platform is a space for inspiration, connection, and
                  spiritual growth. If you have a passion for storytelling, a
                  heart for ministry, and a desire to help others share their
                  journey of faith, we invite you to join us as a volunteer.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="side-img text-end">
                <img className="img-fluid ms-2" src={Sliderimg} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="volunteer-roles">
        <Container>
          <Row className="align-items-center">
            <Col md={4}>
              <h2>Volunteer Roles</h2>
              <p>
                As a ShareHeart Volunteer, you'll play a crucial role in our
                community. Your responsibilities will include:
              </p>
            </Col>
            <Col md={4}>
              <h3>
                <img src={Videorecord} /> Scheduling Video <br />
                Recording Sessions:
              </h3>
              <p>
                Work with individuals ready to share their message,
                coordinating a time that suits them for their virtual recording
                session.
              </p>
            </Col>
            <Col md={4}>
              <h3>
                <img src={Virtualcalls} /> Running Virtual <br /> Calls:
              </h3>
              <p>
                Facilitate the video recording session, ensuring the technology
                works smoothly and the participant feels comfortable and
                supported throughout the process.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="who-volunteer">
        <Container>
          <Row>
            <Col md={4}>
              <div className="man-img">
                <img className="img-fluid" src={Volunteerman} />
              </div>
            </Col>
            <Col md={7}>
              <h2>Who Can Volunteer?</h2>
              <p>We're looking for volunteers who are:</p>
              <ul>
                <li>
                  Compassionate and empathetic, with a good understanding of the
                  Christian faith.
                </li>
                <li>
                  Technically savvy, comfortable with video recording software
                  and online communication platforms.
                </li>
                <li>
                  Excellent communicators, able to coordinate and schedule
                  sessions effectively.
                </li>
                <li>
                  Available to commit a few hours each week to volunteer work.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="how-volunteer">
        <Container>
          <div className="box-volnter">
            <h2>
              How to Volunteer{" "}
              <span>
                If you're interested in volunteering with ShareHeart, please
                fill out the form below. We'll get in touch with more
                information and next steps.
              </span>
            </h2>
            {/* <Button className="cmn-btn" onClick={scrollToElement}>
              Get it now <img className="img-fluid ms-4" src={Tailright} />
            </Button> */}
          </div>
        </Container>
      </section>

      <section className="feedback-section" ref={elementRef}>
        <Container>
          <div className="section-heading text-center" id="targetElement" >
            <h2>Thank You!</h2>
            <p>
              We appreciate your interest in volunteering with ShareHeart. Your
              support is vital to our mission of sharing <br /> and celebrating faith
              journeys. We'll review your submission and get back to you as soon
              as possible.<br /> If you have any questions in the meantime, please
              don't hesitate to contact us at{" "}
              <Link className="link-txt" to="mailto:Hello@shareheart.com">Hello@shareheart.com</Link>
            </p>
          </div>
          <div className="from-feedback">
            {successMessage && (
              <Alert variant="success" onClose={() => setSuccessMessage("")} dismissible>
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
              </Alert>
            )}
            <form className="cmn-frm" onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <label>Full name</label>
                    <input
                      type="text"
                      placeholder="John David"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {formErrors.name && (
                      <p className="text-danger">{formErrors.name}</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label>Email Address</label>
                    <input
                      type="text"
                      placeholder="example@yourmail.com"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {formErrors.email && (
                      <p className="text-danger">{formErrors.email}</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label>Phone number</label>
                    <input
                      type="text"
                      placeholder="+1 914 282 9994"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                    {formErrors.phoneNumber && (
                      <p className="text-danger">{formErrors.phoneNumber}</p>
                    )}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-group">
                    <label>Tell us a little about yourself and
                      why you want to volunteer</label>
                    <textarea
                      placeholder="Hello there"
                      rows="5"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                    {formErrors.message && (
                      <p className="text-danger">{formErrors.message}</p>
                    )}
                  </div>
                </Col>
                <Col md={12} className="text-center">
                  <Button className="cmn-btn rounded-pill px-5" type="submit">Submit</Button>
                </Col>
              </Row>
            </form>
          </div>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default Volunteer;
