import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Header from "../Component/Header";
import { Button, Col, Container, Row, Alert } from "react-bootstrap";
import Background from "../assets/images/background-patterns.svg";
import Footer from "../Component/Footer";
import { serverApi } from "../config";
import { ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

const UploadVideo = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    testimony: ""
  });

  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesList, setSelectedFilesList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to manage form submission
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    AOS.init(); // Initialize AOS library
  }, []);

  const handleChangeVideo = (event) => {
    const files = Array.from(event.target.files);
    const file = event.target.files[0];
    if (!file) return; // No file selected

    // Check if the selected file is a video
    if (!file.type.startsWith('video/')) {
      setErrorMessage('Please select a video file.');
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 5000); // Remove success message after 30 seconds
      return;
    }
    // Check if number of selected files doesn't exceed 10
    if (files.length + selectedFiles.length > 10) {
      setErrorMessage('Maximum 10 videos allowed.');
      ref.current.value = "";
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 5000); // Remove success message after 30 seconds
      return;
    }
    // Check the size of the selected file
    const fileSizeInMb = file.size / (1024 * 1024); // Convert bytes to MB
    if (fileSizeInMb > 500) {
      setErrorMessage('File size exceeds 500 MB limit.');
      ref.current.value = "";
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 5000); // Remove success message after 30 seconds
      return;
    }
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    setSelectedFilesList(file);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Disable the submit button to prevent multiple submissions
    setIsSubmitting(true);

    const errors = validateForm(formData);
    const formDataNew = new FormData();

    formDataNew.append('email', formData.email);
    formDataNew.append('name', formData.name);
    formDataNew.append('phoneNumber', formData.phoneNumber);
    formDataNew.append('testimony', formData.testimony);

    // var videos = [];
    // selectedFiles.forEach((file, index) => {
    //   videos.push(file);
    // });
    for (const file of selectedFiles) {
      formDataNew.append('videos', file);
    }
    if (Object.keys(errors).length === 0) {
      try {
        // Clear any previous errors
        setFormErrors({});

        const config = {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted); // Update the upload progress state
          },
        };

        const response = await axios.post(`${serverApi}/video_upload`, formDataNew, config);
        if (response.data.status === "success") {
          setErrorMessage("");
          setSuccessMessage("Form submitted successfully!");
          ref.current.value = "";
          setFormData({
            name: "",
            email: "",
            phoneNumber: "",
            testimony: ""
          });
          setSelectedFilesList([]);
          setSelectedFiles([]);
          setIsSubmitting(false);
          setTimeout(() => {
            setSuccessMessage("");
          }, 30000); // Remove success message after 30 seconds
          // Set progress to 100% after 5 seconds and then hide after another 5 seconds
        } else {
          setErrorMessage(response.data.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setErrorMessage("Something went wrong!");
        setIsSubmitting(false);
      }
    } else {
      setFormErrors(errors);
      setIsSubmitting(false);
    }

    setTimeout(() => {
      setSuccessMessage("");
      setErrorMessage("");
    }, 5000); // Remove success message after 30 seconds
    // Re-enable the submit button after form submission or error
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.name.trim()) {
      errors.name = "Name is required";
    }
    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
      errors.email = "Invalid email address";
    }
    if (!data.phoneNumber.trim()) {
      errors.phoneNumber = "Phone number is required";
    } else if (!/^\+?\d{0,3}[- ]?\d{3}[- ]?\d{3}[- ]?\d{4}$/.test(data.phoneNumber)) {
      errors.phoneNumber = "Invalid phone number format";
    }
    if (!data.testimony.trim()) {
      errors.testimony = "Testimony is required";
    }
    return errors;
  };

  function uploadVideo() {
    navigate("/share");
  }

  return (
    <div>
      <Header />
      <img className="vatro-img" src={Background} />
      <section className=" hero-section-2">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} md={12}>
              <div className="section-heading text-center">
                <h2>Upload Video</h2>
                <h4>Ready to share ? Upload now</h4>
                <p>
                  If you already have a recorded testimony video, Please upload it here. <br /> Let's spread hope together !
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="feedback-section">
        <Container>
          {uploadProgress > 0 && uploadProgress < 100 && (
            <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
          )}
          <div className="from-feedback">
            {successMessage && (
              <Alert variant="success" onClose={() => setSuccessMessage("")} dismissible>
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
              </Alert>
            )}
            <form className="cmn-frm" onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <label>Full name</label>
                    <input
                      type="text"
                      placeholder="John David"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {formErrors.name && (
                      <p className="text-danger">{formErrors.name}</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label>Email Address</label>
                    <input
                      type="text"
                      placeholder="example@yourmail.com"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {formErrors.email && (
                      <p className="text-danger">{formErrors.email}</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label>Phone number</label>
                    <input
                      type="text"
                      placeholder="+1 914 282 9994"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                    {formErrors.phoneNumber && (
                      <p className="text-danger">{formErrors.phoneNumber}</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label>Write your testimony</label>
                    <textarea
                      placeholder="Hello there"
                      rows="2"
                      name="testimony"
                      value={formData.testimony}
                      onChange={handleChange}
                    />
                    {formErrors.testimony && (
                      <p className="text-danger">{formErrors.testimony}</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label>Upload Video</label>
                    <input
                      ref={ref}
                      type="file"
                      name="files"
                      onChange={handleChangeVideo}
                      multiple
                      accept="video/*"
                    />
                  </div>
                </Col>
                <div className="row">
                  {selectedFiles.map((file, index) => (
                    <div className="col-sm-3" key={index}>
                      <video autoPlay={true} muted={true} controls width="250" height="150">
                        <source src={URL.createObjectURL(file)} type="video/mp4" />
                      </video>
                    </div>
                  ))}
                </div>
                <Col md={12} className="text-center">
                  <Button className="cmn-btn rounded-pill px-5" type="submit" disabled={isSubmitting}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        </Container>
      </section>
      <section className="get-touch-section">
        <div className="section-heading mb-0">
          <h2 >Need guidance on sharing your story?</h2>
          <p >Get in touch with us for a free video recording call. Our coordinators will talk with you on the call to discover, refine, and record your story.</p>
          <button  onClick={uploadVideo}>Get in Touch</button>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default UploadVideo;
